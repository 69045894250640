import { Modal } from 'components'
import { BiPencil } from 'react-icons/bi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useToggle } from 'hooks'
import { DeleteCategory } from '../'
import { languageAudit } from 'locale'
import {
  DeleteBtn,
  EditBtn,
} from '../EstablishmentItem/Establishmentitem.styled'
import { CharListCategory, CharListItem } from './CategoryItem.styled'
import { t } from 'i18next'
type Tprops = {
  title: { name: string; lang: string }[]
  image: string
  show: boolean
  id: string
  establishments: number
}
const firstPartCategoryImg = 'https://dev-api.radius.kitg.com.ua/public/image/'
export const CategoryItem: React.FC<Tprops> = ({
  title,
  image,
  id,
  show,
  establishments,
}) => {
  const { isOpen, open, close } = useToggle(false)
  const handleDeleteClick = () => {
    close()
  }

  const language = localStorage.getItem('language')

  return (
    <>
      {isOpen && (
        <Modal closeModal={handleDeleteClick}>
          <DeleteCategory id={id} close={close} />
        </Modal>
      )}
      <CharListItem>
        <CharListCategory>
          <li>
            <img src={firstPartCategoryImg + image} alt={title[0].name} />
            <p>{title[languageAudit(language || 'en') || 0].name}</p>
          </li>
          <li>{establishments}</li>
          <li>
            <div id={show ? 'show' : 'hidden'}></div>{' '}
            {show ? t('category.show') : t('category.hidden')}
          </li>
          <li>
            <EditBtn to={`/edit-category/${id}`}>
              <BiPencil />
            </EditBtn>
          </li>
          <li>
            {' '}
            <DeleteBtn onClick={open}>
              <RiDeleteBinLine />
            </DeleteBtn>
          </li>
        </CharListCategory>
      </CharListItem>
    </>
  )
}
