import styled from 'styled-components'
export const CharListItem = styled.li`
  border-bottom: 1px solid #ededed;
  padding: 12px 20px;
  height: 68px;
`
export const CharListCategory = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  li {
    display: flex;
    align-items: center;

    &:nth-child(1) {
      padding: 0 10px 0 0;
      width: 430px;
      img {
        object-fit: cover;
        width: 44px;
        height: 44px;
        margin-right: 8px;
        border-radius: 8px;
      }
    }
    &:nth-child(2) {
      padding-left: 3px;
      width: 284px;
    }
    &:nth-child(3) {
      padding-left: 3px;
      div {
        margin-right: 8px;
        background-color: #dd1919;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        &#show {
          background-color: #19dda2;
        }
      }
      width: 293px;
    }
    &:nth-child(4) {
      width: 78px;
    }
    &:nth-child(5) {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`
