import styled from 'styled-components'

export const SortListEditBooking = styled.ul`
  display: flex;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
  li {
    display: flex;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    height: 60px;
    &#active {
      color: #2bb54b;
      &.up {
        svg {
          rotate: 0deg;
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      rotate: 180deg;
      transition: rotate 300ms;
    }

    &:nth-child(1) {
      padding: 0 10px 0 0;
      width: 87px;
    }
    &:nth-child(2) {
      width: 453px;
    }
    &:nth-child(3) {
      cursor: pointer;
      width: 300px;
    }
    &:nth-child(4) {
      width: 225px;
      cursor: pointer;
    }
  }
`
export const CustomerBookingList = styled.ul`
  li {
    border-bottom: 1px solid #ededed;
    padding: 0 20px;
    ul {
      display: flex;
      li {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 68px;

        :nth-child(1) {
          width: 87px;
          padding: 0 10px 0 0;
        }
        :nth-child(2) {
          width: 453px;
          gap: 8px;
          img {
            object-fit: cover;
            width: 44px;
            height: 44px;
            border-radius: 8px;
          }
          p {
            display: flex;
            align-items: center;
            width: 152px;
            height: 40px;
            overflow: auto;
          }
        }
        :nth-child(3) {
          width: 300px;
        }
        :nth-child(4) {
          width: 225px;
        }
        :nth-child(5) {
          padding: 0;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 44px;
            height: 44px;
            background: #2bb54b;
            border-radius: 8px;
            svg {
              width: 20px;
              height: 20px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`
