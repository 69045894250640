import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { DatePicker } from 'antd'
import { DeleteCustomer } from './EditInfoCustomer.styled'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { nanoid } from 'nanoid'
import { FormDeleteCustomer, Modal } from 'components'
import dayjs from 'dayjs'
import { Image } from 'antd'
import Resizer from 'react-image-file-resizer'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch, useToggle } from 'hooks'
import { allCustomersAction } from 'store/Customers'
import { useTranslation } from 'react-i18next'

import {
  CustomerInfoWrap,
  CustomerForm,
  SubmitCustomerBtn,
  RightWrapCustomer,
  LeftWrapCustomer,
  InfoCustomerWrap,
  ErrorCustomerMessage,
  ImageWrap,
} from 'pages'
import { Controller, useForm } from 'react-hook-form'
import { RiDeleteBinLine, RiUploadFill } from 'react-icons/ri'
import { ChangeEvent, useEffect, useState } from 'react'
type TForm = {
  name: string
  surname: string
  phone: string
  email: string
  photo: File
  status: string
  date: string
}
const firstPartImg = 'https://dev-api.radius.kitg.com.ua/public/avatar/'

export const EditInfoCustomer: React.FC = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { isOpen, open, close } = useToggle(false)
  const { t } = useTranslation()
  useEffect(() => {
    id && dispatch(allCustomersAction.getUserAction(id))
  }, [dispatch, id])
  const navigate = useNavigate()

  const { currentCustomer } = useAppSelector(state => state.customerState)
  const minLength = 3
  const [photo, setPhoto] = useState<{
    id: string
    url: string | File | Blob | ProgressEvent<FileReader>
    file: File | string
  } | null>(null)
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<TForm>({ mode: 'all' })

  const onSubmit = async (data: TForm) => {
    const formData = new FormData()
    photo?.file
      ? formData.append('avatar', photo.file)
      : !photo?.url && formData.append('avatar', 'null')

    formData.append('name', data.name)
    formData.append('surname', data.surname)
    formData.append('phone', '+' + data.phone)
    data.email && formData.append('email', data.email)
    data.date && formData.append('birthDate', data.date)
    formData.append('blocked', data.status === 'active' ? 'false' : 'true')
    if (id) {
      dispatch(allCustomersAction.editCustomerAction({ id, data: formData }))
      navigate('/customers')
    }
  }
  useEffect(() => {
    const activeLink = document.getElementById('customers')
    activeLink?.classList.add('activePage')
    return () => {
      activeLink?.classList.remove('activePage')
    }
  }, [])
  useEffect(() => {
    if (currentCustomer) {
      currentCustomer.birthDate && setValue('date', currentCustomer.birthDate)
      currentCustomer.phone &&
        setValue(
          'phone',
          currentCustomer.phone.slice(1, currentCustomer.phone.length),
        )
      currentCustomer.name && setValue('name', currentCustomer.name)
      currentCustomer.surname && setValue('surname', currentCustomer.surname)
      currentCustomer.email && setValue('email', currentCustomer.email)
      setValue('status', currentCustomer.blocked ? 'blocked' : 'active')
      setPhoto({
        id: nanoid(4),
        file: '',
        url:
          currentCustomer.avatar && currentCustomer.avatar !== 'null'
            ? currentCustomer.avatar.toString()
            : '',
      })
    }
  }, [currentCustomer, setValue])

  const handlePicChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.currentTarget.files
    if (newFiles) {
      ;[...newFiles].map(item => {
        return Resizer.imageFileResizer(
          item,
          1200,
          1200,
          'JPEG',
          100,
          0,
          uri => {
            setPhoto({ id: nanoid(3), url: uri, file: item })
          },
          'base64',
        )
      })
    }
  }

  const deleteClick = () => {
    setPhoto(null)
  }
  return (
    <CustomerInfoWrap>
      {isOpen && (
        <Modal closeModal={close}>
          <FormDeleteCustomer id={id} close={close} />
        </Modal>
      )}
      <DeleteCustomer onClick={open}>
        <RiDeleteBinLine />
        {t('establishment.delete')}
      </DeleteCustomer>
      <PageWrapper className="new__customer">
        <CustomerForm onSubmit={handleSubmit(onSubmit)}>
          <SubmitCustomerBtn className="edit">
            {t('establishment.update')}
          </SubmitCustomerBtn>
          <LeftWrapCustomer>
            <p>{t('establishment.basicInfo')}</p>
            <InfoCustomerWrap>
              <label>
                {t('name')}
                <input
                  {...register('name', {
                    required: t('errorsInput.required')!,
                    minLength: {
                      value: minLength,
                      message: t('errorsInput.minLetter', { minLength }),
                    },
                  })}
                />{' '}
                <ErrorCustomerMessage>
                  {errors?.name && <p>{errors.name.message}</p>}
                </ErrorCustomerMessage>
              </label>

              <label>
                {t('surname')}
                <input
                  {...register('surname', {
                    required: t('errorsInput.required')!,
                    minLength: {
                      value: minLength,
                      message: t('errorsInput.minLetter', { minLength }),
                    },
                  })}
                />{' '}
                <ErrorCustomerMessage>
                  {errors?.surname && <p>{errors.surname.message}</p>}
                </ErrorCustomerMessage>
              </label>

              <label>
                {t('establishment.phone')}
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: t('errorsInput.required')! }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      inputClass="phone__input"
                      placeholder="Enter phone number"
                      value={value}
                      onChange={onChange}
                      containerClass="phone__wrap"
                    />
                  )}
                />
                <ErrorCustomerMessage>
                  {errors?.phone && <p>{errors.phone.message}</p>}
                </ErrorCustomerMessage>
              </label>
              <label>
                {' '}
                Email
                <input {...register('email')} type="email" />
              </label>
            </InfoCustomerWrap>
            <p className="date__birthday">{t('birthday')}</p>
            <Controller
              control={control}
              name="date"
              rules={{ required: false }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  style={{
                    height: '44px',
                    width: '342px',
                  }}
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  format="DD-MM-YYYY"
                  showToday={false}
                  placeholder="Select date"
                />
              )}
            />
            <p className="photo__title">{t('photo')}</p>
            {photo?.url ? (
              <ImageWrap>
                <Image
                  preview={{ maskClassName: 'image__mask' }}
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                  height={219}
                  width={219}
                  src={
                    !photo.file
                      ? firstPartImg + photo.url.toString()
                      : photo?.url.toString()
                  }
                  alt={'avatar'}
                />
                <button onClick={deleteClick}>
                  <RiDeleteBinLine />
                </button>
              </ImageWrap>
            ) : (
              <label className="photo__label">
                {' '}
                <div>
                  <RiUploadFill />
                  {t('establishment.filesUpload')}
                </div>{' '}
                <input
                  {...register('photo')}
                  type="file"
                  className="visually-hidden"
                  accept="image/*,.png,.jpg,.web,.gif"
                  onChange={handlePicChange}
                />{' '}
              </label>
            )}
          </LeftWrapCustomer>
          <RightWrapCustomer>
            <p>{t('establishment.organization')}</p>
            <div>
              <label>
                {t('establishment.status')}
                <select {...register('status')}>
                  <option value="active">{t('active')}</option>
                  <option value="blocked">{t('blocked')}</option>
                </select>
              </label>
            </div>
          </RightWrapCustomer>
        </CustomerForm>
      </PageWrapper>
    </CustomerInfoWrap>
  )
}
