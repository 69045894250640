import { createAction } from "@reduxjs/toolkit";
import { rolesActions } from "./reducer";
import { TNewRole } from "./types";
export const allRolesAction = {
    ...rolesActions,
    getRolesAction: createAction<{
        limit: string
        page: number
        search?: string,
        sortBy?: string
        order?: string
        startDate?: string
        endDate?: string
    }>('rolesState/getRolesAction'),
    createRoleApi: createAction<TNewRole>('rolesState/createRoleApi'),
    editRoleAction: createAction<{ data: TNewRole, id: string }>('rolesState/editRoleAction'),
    getCurrentRoleAction: createAction<string>('rolesState/getCurrentRoleAction'),
    deleteRoleAction:createAction<string>('rolesState/deleteRoleAction')
    
}