import { PageWrapper, Pagination } from 'components'
import { CustomerInfoWrap } from 'pages'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'
import format from 'date-fns/format'
import { allBookingActions } from 'store/BookingsStore'
import { useAppSelector, useAppDispatch } from 'hooks'
import { IoIosArrowDown } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { selectDateLocal } from 'locale'
import { languageAudit } from 'locale'
import {
  SortListEditBooking,
  CustomerBookingList,
} from './CustomerEditBooking.styled'
import { SelectLimitCategory } from 'pages/Categories/Categories.styled'
import { BottomCustomersWrap } from 'pages/Customers/Customer.styled'
import { AiOutlineEye } from 'react-icons/ai'

const firstPartImg =
  'https://dev-api.radius.kitg.com.ua/public/establishment/images/'

export const CustomerEditBooking: React.FC = () => {
  const { limit, page, bookingsList, bookingsCount } = useAppSelector(
    state => state.bookingState,
  )
  const { t } = useTranslation()
  const totalPage = Math.ceil(bookingsCount / +limit)
  const iserId = useAppSelector(state => state.customerState.currentCustomer.id)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [sort, setSort] = useState<'persons' | 'createdAt'>('persons')
  const [growth, setGrowth] = useState<boolean>(true)
  useEffect(() => {
    const activeLink = document.getElementById('customers')
    activeLink?.classList.add('activePage')
    return () => {
      activeLink?.classList.remove('activePage')
    }
  }, [])
  useEffect(() => {
    dispatch(
      allBookingActions.getBookingAction({
        limit,
        page,
        user: iserId,
        sortBy: sort,
        order: growth ? '1' : '-1',
      }),
    )
  }, [dispatch, growth, iserId, limit, page, sort])
  const handleLimitPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(allBookingActions.changeLimit(e.target.value))
  }
  const onPageClick = ({ selected }: { selected: number }) => {
    dispatch(allBookingActions.changePage(selected + 1))
  }
  const language = localStorage.getItem('language')
  return (
    <CustomerInfoWrap>
      <PageWrapper className="new__customer ">
        <SortListEditBooking>
          <li>№</li>
          <li> {t('establishment.establishment')}</li>
          <li
            onClick={() => {
              setSort('createdAt')
              setGrowth(prev => !prev)
            }}
            id={sort === 'createdAt' ? 'active' : 'inactive'}
            className={growth ? 'up' : 'down'}>
            {' '}
            {t('bookingsDate')}
            <IoIosArrowDown />
          </li>
          <li
            onClick={() => {
              setSort('persons')
              setGrowth(prev => !prev)
            }}
            id={sort === 'persons' ? 'active' : 'inactive'}
            className={growth ? 'up' : 'down'}>
            {t('persons')}
            <IoIosArrowDown />
          </li>
        </SortListEditBooking>
        <CustomerBookingList>
          {bookingsList.map(item => {
            return (
              <li key={nanoid(4)}>
                <ul>
                  <li>{item.index}</li>
                  <li>
                    <img
                      alt={item.establishment.title[0].value}
                      src={firstPartImg + item.establishment.images[0].name}
                    />
                    <p>
                      {
                        item.establishment.title[
                          languageAudit(language || 'en') || 0
                        ].value
                      }
                    </p>
                  </li>
                  <li>
                    {' '}
                    {format(new Date(item.date), 'MMM d, YYY', {
                      locale: selectDateLocal(language),
                    })}{' '}
                    {t('at')}{' '}
                    {format(new Date(item.date), 'p', {
                      locale: selectDateLocal(language),
                    })}
                  </li>
                  <li>{item.persons}</li>
                  <li>
                    {' '}
                    <div onClick={() => navigate(`/booking/${item.id}`)}>
                      {' '}
                      <AiOutlineEye />
                    </div>
                  </li>
                </ul>
              </li>
            )
          })}
        </CustomerBookingList>
        {totalPage !== 0 && (
          <BottomCustomersWrap>
            <div>
              {' '}
              {t('showing')}{' '}
              {page === 1
                ? 1
                : page === totalPage
                ? (page - 1) * +limit + 1
                : page * +limit + 1 - +limit}
              -
              {page === 1
                ? bookingsList.length
                : +page === +totalPage
                ? bookingsCount
                : +limit * +page}{' '}
              {t('of')} {bookingsCount} {t('items')}
            </div>
            <div>
              {totalPage > 1 && (
                <Pagination
                  onPageChange={onPageClick}
                  pageCount={totalPage}
                  initialPage={page - 1}
                />
              )}
            </div>
            <div>
              {' '}
              {t('show')}
              <SelectLimitCategory value={limit} onChange={handleLimitPage}>
                <option value="2">2</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </SelectLimitCategory>
              {t('items')}
            </div>
          </BottomCustomersWrap>
        )}
      </PageWrapper>
    </CustomerInfoWrap>
  )
}
