import { AppLink } from './AppBar.styled'
import { FiUsers } from 'react-icons/fi'
import { TbLayoutDashboard } from 'react-icons/tb'
import { useAppDispatch, useAppSelector } from 'hooks'
import { RiBankLine, RiFileCopy2Line } from 'react-icons/ri'
import { loginAdminAction } from 'store/AdminAuth/action'
import { useTranslation } from 'react-i18next'

import {
  RiUserSettingsLine,
  RiLogoutBoxLine,
  RiBarChartHorizontalLine,
} from 'react-icons/ri'
import { IoIosArrowDown } from 'react-icons/io'
import {
  Nav,
  AppBarWrapper,
  EstablishmentWrap,
  LinkList,
  LinkItem,
  DropLinkList,
  DropTitle,
  LogOutWrap,
  LogOutLink,
} from './AppBar.styled'
import { useState } from 'react'
export const AppBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(true)
  const onDropClick = () => {
    setVisible(true)
  }
  const closeDrop = () => {
    setVisible(false)
  }
  const onExitClick = () => {
    dispatch(loginAdminAction.authExit())
  }
  const { role } = useAppSelector(state => state.authState)

  return (
    <AppBarWrapper>
      <Nav>
        <LinkList>
          <LinkItem>
            <AppLink onClick={closeDrop} to="/">
              {' '}
              <TbLayoutDashboard />
              {t('dashboard')}
            </AppLink>
          </LinkItem>
          <LinkItem>
            <EstablishmentWrap>
              <DropTitle
                id={visible ? 'visible' : 'hidden'}
                onClick={onDropClick}>
                <RiBankLine />
                <p>{t('establishments')} </p>
                <IoIosArrowDown
                  className="arrow"
                  id={visible ? 'visible' : 'hidden'}
                />
              </DropTitle>
              <DropLinkList id={visible ? 'visible' : 'hidden'}>
                <li>
                  <AppLink to="/establishments">
                    {t('curentEstablishment')}
                  </AppLink>
                </li>
                <li>
                  <AppLink to="/pending">{t('pending')}</AppLink>
                </li>
              </DropLinkList>
            </EstablishmentWrap>
          </LinkItem>
          <LinkItem>
            {' '}
            <AppLink
              id="category"
              onClick={closeDrop}
              to="/categories"
              className={role === 'scout' ? 'visually-hidden' : ''}>
              <RiBarChartHorizontalLine />
              {t('categories')}
            </AppLink>
          </LinkItem>
          <LinkItem>
            {' '}
            <AppLink
              id="customers"
              onClick={closeDrop}
              to="/customers"
              className={role === 'scout' ? 'visually-hidden' : ''}>
              <FiUsers />
              {t('customers')}
            </AppLink>
          </LinkItem>
          <LinkItem>
            {' '}
            <AppLink
              id="bookings"
              onClick={closeDrop}
              to="/bookings"
              className={role === 'scout' ? 'visually-hidden' : ''}>
              <RiFileCopy2Line />
              {t('bookings')}
            </AppLink>
          </LinkItem>
          <LinkItem>
            {' '}
            <AppLink
              id="roles"
              onClick={closeDrop}
              to="/roles"
              className={role === 'scout' ? 'visually-hidden' : ''}>
              <RiUserSettingsLine />
              {t('roles')}
            </AppLink>
          </LinkItem>
        </LinkList>
        <LogOutWrap>
          <LogOutLink onClick={onExitClick}>
            <RiLogoutBoxLine />
            {t('logout')}
          </LogOutLink>
        </LogOutWrap>
      </Nav>
    </AppBarWrapper>
  )
}
