import { BiPencil } from 'react-icons/bi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { format } from 'date-fns'
import { Modal } from 'components'
import { useToggle } from 'hooks'
import { FormDelete } from '../FormDelete/FormDelete'
import { selectDateLocal, languageAudit } from 'locale'
import { useTranslation } from 'react-i18next'
import {
  EstablishmentList,
  EstablishmentPoint,
  EditBtn,
  DeleteBtn,
  EstablishmentLi,
} from './Establishmentitem.styled'

type TProps = {
  title: string
  id: string
  category: {
    title: { name: string; lang: string }[]
    image: string
    id: string
  }
  address: string
  status: string
  createdAt: string
  index: number
  images: { name: string }[]
}

export const EstablishmentItem: React.FC<TProps> = ({
  category,
  images,
  address,
  status,
  createdAt,
  index,
  title,
  id,
}) => {
  const language = localStorage.getItem('language')
  const { t } = useTranslation()
  const { isOpen, open, close } = useToggle(false)

  const firstPartImg =
    'https://dev-api.radius.kitg.com.ua/public/establishment/images/'

  return (
    <EstablishmentLi id={id}>
      <EstablishmentList>
        <EstablishmentPoint>{index}</EstablishmentPoint>
        <EstablishmentPoint>
          <img src={firstPartImg + images?.[0]?.name} alt={title} />
          <p>{title}</p>
        </EstablishmentPoint>
        {category && (
          <EstablishmentPoint>
            {category.title[languageAudit(language || 'en') || 0].name}
          </EstablishmentPoint>
        )}
        <EstablishmentPoint>
          {format(new Date(createdAt), 'MMM d, YYY', {
            locale: selectDateLocal(language),
          })}{' '}
          {t('at')}{' '}
          {format(new Date(createdAt), 'p', {
            locale: selectDateLocal(language),
          })}
        </EstablishmentPoint>
        <EstablishmentPoint>{address.slice(0, 100)}</EstablishmentPoint>
        <EstablishmentPoint>
          <div id={status.toLowerCase()}></div>
          {t(`status.${status}`)}
        </EstablishmentPoint>
        <EstablishmentPoint>
          <EditBtn to={`/edit/${id}`}>
            <BiPencil />
          </EditBtn>
        </EstablishmentPoint>
        <EstablishmentPoint>
          <DeleteBtn onClick={open}>
            <RiDeleteBinLine />
          </DeleteBtn>
          {isOpen && (
            <Modal closeModal={close}>
              <FormDelete id={id} close={close} />
            </Modal>
          )}
        </EstablishmentPoint>
      </EstablishmentList>
    </EstablishmentLi>
  )
}
