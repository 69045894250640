import { call, put, takeLatest } from 'redux-saga/effects'
import { store } from 'store/store'
import { t } from 'i18next';
import {
  getEstablishment,
  deleteEstablishment,
  approveItem,editEstablishment,
  rejectItem,getEstablishmentForId,createEstablishment
} from './api'
import { toast } from 'react-toastify'

import { allEstablishmentActions } from './action'
import { TResponse, TDeleteSuccess,TEstablishmentData } from './types'
const {
  changeLoading,
  establishmentError,
  fetchEstablishmentFulfilled,
  getEstablishmentData,createNewEstablishment,
  deleteItemRequest,
  deleteItem,
  approveItemRequest,updateEstablishment,
  rejectItemRequest,getCurrentEstablishment,getInstitutionForId
} = allEstablishmentActions

function* fetchEstablishmentWorker({
  payload
}: ReturnType<typeof getEstablishmentData>) {
  try {
    yield put(changeLoading(true))
    const response: TResponse = yield call(getEstablishment, payload)
    if (response) yield put(fetchEstablishmentFulfilled(response))
  } catch (error) {
    yield put(establishmentError(true))
  }
}

function* deleteWorker({ payload }: ReturnType<typeof deleteItemRequest>) {
  try {
    yield put(changeLoading(true))
    const response: TDeleteSuccess = yield call(deleteEstablishment, payload)
    if (response) {
        yield put(changeLoading(false))
      deleteItem(payload)
      toast.success(t('errorsInput.deleteSuccess'))
    }
  } catch (error) {
    yield put(establishmentError(true))
   toast.error(t('errorsInput.toastError')) 
  }
}
function* approveWorker({ payload }: ReturnType<typeof approveItemRequest>) {
  yield put(changeLoading(true))
  const { limit, page } = store.getState().establishmentState;
  try {
    yield call(approveItem, payload)
    yield put(changeLoading(false))
    yield put(getEstablishmentData({
        limit,
        page,
        status: ['pending', 'unpublished'],
}))
    toast.success(t('errorsInput.published'))

  } catch (error) {
    yield put(establishmentError(true))
    toast.error(t('errorsInput.toastError'))  

  }
}
function* rejectWorker({
  payload,
}: ReturnType<typeof rejectItemRequest>) {
  yield put(changeLoading(true))
  const { limit, page } = store.getState().establishmentState;
  try {
    yield call(rejectItem, payload)
    yield put(changeLoading(false))
       yield put(getEstablishmentData({
        limit,
        page,
        status: ['pending', 'unpublished'],
}))
    toast.warn(t('errorsInput.reject'))

  } catch (error) {
    yield put(establishmentError(true))
    toast.error(t('errorsInput.toastError')) 

  }
}

function* getCurrentWorker({ payload }: ReturnType<typeof getCurrentEstablishment>) {
  yield put(changeLoading(true))
  try {
    const response:TEstablishmentData = yield call(getEstablishmentForId, payload);
    yield put (getInstitutionForId(response))
  } catch (error) {
     yield put(establishmentError(true))
  }
}

function* createEstablishmentWorker({ payload }: ReturnType<typeof createNewEstablishment>) {
  yield put(changeLoading(true))
  try {
    const { limit, page } = store.getState().establishmentState;
    yield call(createEstablishment, payload)
    
     toast.success(t('errorsInput.createdSuccess'))
yield put(getEstablishmentData({
        limit,
        page,
        status: ['published'],
        sortBy: 'title',
        order: '1',
}))
    yield put(changeLoading(false))
  } catch (error) {
    yield put(establishmentError(true))
     toast.error(t('errorsInput.toastError'))
  }
}
function* editEstablishmentWorker({ payload }: ReturnType<typeof updateEstablishment>) {
    yield put(changeLoading(true))
  try {
 
  yield call(editEstablishment, payload)
    yield put(changeLoading(false))
     toast.success(t('errorsInput.changedSuccess'))

} catch (error) {
  yield put(establishmentError(true))
  toast.error(t('errorsInput.toastError'))
}
}


export function* userWatcher() {
  yield takeLatest(getEstablishmentData, fetchEstablishmentWorker)
  yield takeLatest(deleteItemRequest, deleteWorker)
  yield takeLatest(approveItemRequest, approveWorker)
  yield takeLatest(rejectItemRequest, rejectWorker)
  yield takeLatest(getCurrentEstablishment, getCurrentWorker)
  yield takeLatest(createNewEstablishment, createEstablishmentWorker)
  yield takeLatest(updateEstablishment,editEstablishmentWorker)
  
}
