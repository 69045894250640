import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const BookingListItem = styled.li`
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
  ul {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 68px;

      :nth-child(1) {
        padding: 0 10px 0 0;
        width: 87px;
      }
      :nth-child(2) {
        width: 250px;
        gap: 8px;
        img {
          object-fit: cover;
          width: 44px;
          height: 44px;
          border-radius: 8px;
        }
        p {
          display: flex;
          align-items: center;
          width: 132px;
          height: 40px;
          overflow: auto;
          flex-wrap: wrap;
        }
      }
      :nth-child(3) {
        width: 222px;
      }
      :nth-child(4) {
        width: 200px;
      }
      :nth-child(5) {
        width: 171px;
      }
      :nth-child(6) {
        width: 140px;
        padding-left: 0;
        div {
          margin-right: 8px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          &.pending {
            background-color: #f59e0b;
          }
          &.confirmed {
            background-color: #19dda2;
          }
          &.cancelled {
            background-color: #dd1919;
          }
        }
      }
      :nth-child(7) {
        padding: 0;
      }
    }
  }
`
export const BookingLink = styled(Link)`
  width: 44px;
  height: 44px;

  background-color: #2bb54b;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  svg {
    color: #ffffff;
    width: 20px;
    height: 20px;
  }
`
