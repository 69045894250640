import { RxCross2 } from 'react-icons/rx'
import { useForm, Controller } from 'react-hook-form'
import { TData } from './types'
import { useEffect } from 'react'
import { Checkbox } from '../'
import { useTranslation } from 'react-i18next'
import {
  FilterWrap,
  Itemlist,
  DayWrap,
  ButtonWrap,
  TitleWrap,
} from './FilterCurrent.styled'
import { useAppSelector, useAppDispatch } from '../../hooks'
import {
  allEstablishmentActions,
  TPriceRange,
  TWorkingTime,
} from '../../store/Establishment'
type TProps = {
  close: () => void
  currentPage: string
}
export const FilterWindow: React.FC<TProps> = ({ close, currentPage }) => {
  const { handleSubmit, control, setValue, reset } = useForm<TData>()
  const {
    filterDay,
    filterPrice,
    filterShow,
    filterPendingDay,
    filterPendingPrice,
    filterPendingShow,
  } = useAppSelector(state => state.establishmentState)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    if (currentPage === 'current') {
      filterDay.map(item => setValue(item, true))
      filterPrice.map(item => setValue(item, true))
      if (filterShow === '') {
        setValue('show', false)
        setValue('hidden', false)
      }
      if (filterShow === 'true') {
        setValue('show', true)
      }
      if (filterShow === 'false') {
        setValue('hidden', true)
      }
    }
    if (currentPage === 'pending') {
      filterPendingDay.map(item => setValue(item, true))
      filterPendingPrice.map(item => setValue(item, true))
      if (filterPendingShow === '') {
        setValue('show', false)
        setValue('hidden', false)
      }
      if (filterPendingShow === 'true') {
        setValue('show', true)
      }
      if (filterPendingShow === 'false') {
        setValue('hidden', true)
      }
    }
  }, [
    currentPage,
    filterDay,
    filterPendingDay,
    filterPendingPrice,
    filterPendingShow,
    filterPrice,
    filterShow,
    setValue,
  ])

  const onSubmit = (data: TData) => {
    const nedDays: { day: TWorkingTime; value: boolean }[] = [
      { day: 'monday', value: data.monday },
      { day: 'tuesday', value: data.tuesday },
      { day: 'wednesday', value: data.wednesday },
      { day: 'thursday', value: data.thursday },
      { day: 'friday', value: data.friday },
      { day: 'saturday', value: data.saturday },
      { day: 'sunday', value: data.sunday },
    ]
    const needPrice: { price: TPriceRange; value: boolean }[] = [
      { price: '$', value: data.$ },
      { price: '$$', value: data.$$ },
      { price: '$$$', value: data.$$$ },
      { price: '$$$$', value: data.$$$$ },
    ]
    dispatch(
      allEstablishmentActions.changeFilter({
        name: currentPage,
        day: nedDays.filter(item => item.value).map(item => item.day),
        show: data.hidden ? 'false' : !data.hidden && !data.show ? '' : 'true',
        price: needPrice.filter(item => item.value).map(item => item.price),
      }),
    )
    close()
  }

  const resetClick = () => {
    reset()
  }
  return (
    <FilterWrap onSubmit={handleSubmit(onSubmit)}>
      <TitleWrap>
        <p>{t('filterTitle')}</p>
        <button onClick={close}>
          <RxCross2 />
        </button>
      </TitleWrap>
      <DayWrap>
        <div>
          <Itemlist>
            <li>{t('establishment.day')}</li>
            <li>
              <label>{t('monday')} </label>
              <Controller
                name="monday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('tuesday')} </label>
              <Controller
                name="tuesday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('wednesday')} </label>
              <Controller
                name="wednesday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('thursday')} </label>
              <Controller
                name="thursday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('friday')} </label>
              <Controller
                name="friday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('saturday')} </label>
              <Controller
                name="saturday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label>{t('sunday')} </label>
              <Controller
                name="sunday"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
          </Itemlist>
        </div>
        <div>
          <Itemlist>
            <li>{t('price')}</li>
            <li>
              <label htmlFor="$">$</label>
              <Controller
                name="$"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label htmlFor="$$">$$</label>
              <Controller
                name="$$"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label htmlFor="$$$">$$$</label>
              <Controller
                name="$$$"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
            <li>
              <label htmlFor="$$$$">$$$$</label>
              <Controller
                name="$$$$"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox value={value} onChange={onChange} />
                )}
              />
            </li>
          </Itemlist>
        </div>
        <div>
          <Itemlist>
            <li>{t('establishment.visibility')}</li>
            <li>
              <label htmlFor="">{t('showFilter')}</label>
              <Controller
                name="show"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    setValue={setValue}
                    name={'show'}
                  />
                )}
              />
            </li>
            <li>
              <label htmlFor="">{t('hiddenFilter')}</label>
              <Controller
                name="hidden"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    setValue={setValue}
                    name={'hidden'}
                  />
                )}
              />
            </li>
          </Itemlist>
        </div>
      </DayWrap>
      <ButtonWrap>
        <button type="button" onClick={resetClick}>
          {t('reset')}
        </button>
        <button type="submit">{t('apply')}</button>
      </ButtonWrap>
    </FilterWrap>
  )
}
