import {
  DeleteAfterTitle,
  DeleteBtnWrap,
  DeleteTitle,
  DeleteWrap,
  TitleWrap,
} from 'components'
import { RiDeleteBinLine } from 'react-icons/ri'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks'
import { allRolesAction } from 'store/Roles'
import { useTranslation } from 'react-i18next'
type TProps = {
  id?: string
  close: () => void
}
export const FormDeleteRole: React.FC<TProps> = ({ id, close }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const onDeleteClick = async () => {
    id && dispatch(allRolesAction.deleteRoleAction(id))
    close()
    navigate('/roles')
  }
  return (
    <DeleteWrap>
      <TitleWrap>
        <DeleteTitle>{t('rolesLast.deletingTheRole')}</DeleteTitle>
        <button onClick={close}>
          <RxCross2 />
        </button>
      </TitleWrap>
      <DeleteAfterTitle>{t('rolesLast.youSure')}</DeleteAfterTitle>
      <DeleteBtnWrap>
        <button onClick={onDeleteClick}>
          <RiDeleteBinLine />
          <p>{t('establishment.delete')}</p>
        </button>
        <button onClick={close}>{t('rolesLast.cancel')}</button>
      </DeleteBtnWrap>
    </DeleteWrap>
  )
}
