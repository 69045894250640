import styled from 'styled-components'

export const NewRolesFormWrap = styled.div`
  padding: 0 20px;
  height: 842px;
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 24px;
  }
`
export const NewRolesForm = styled.form`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43px;
  display: grid;
  grid-template-columns: 342px 342px;
  grid-row-gap: 28px;
  grid-column-gap: 28px;
  label {
    position: relative;
    input {
      margin-top: 16px;
      padding: 10px 12px;
      width: 100%;
      height: 44px;
      border: 1px solid #e1e1e1;
      border-radius: 8px;
      outline: none;
    }
    select {
      margin-top: 16px;
    }
    svg {
      color: #9e9e9e;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: 29px;
      right: 10px;
    }
  }

  button {
    cursor: pointer;
    top: -135px;
    right: -20px;
    position: absolute;
    border: none;
    background-color: transparent;
    width: 102px;
    height: 44px;
    color: #ffffff;
    background-color: #2bb54b;
    border-radius: 8px;

    &.edit__info__role {
      top: -185px;
    }
  }
`
export const ErrorMessageRoles = styled.div`
  position: absolute;
  color: red;
  left: 8px;
  /* bottom: 4px; */
  p {
    font-weight: 400;
    font-size: 14px;
  }
`
