import styled from 'styled-components'
export const SortList = styled.ul`
  display: flex;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
  li {
    display: flex;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    height: 60px;
    &#active {
      color: #2bb54b;
      &.up {
        svg {
          rotate: 0deg;
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      rotate: 180deg;
      transition: rotate 300ms;
    }
    &:nth-child(1) {
      width: 67px;
      padding: 0 10px 0 0;
    }

    &:nth-child(2) {
      width: 228px;
      cursor: pointer;
    }
    &:nth-child(3) {
      width: 182px;
    }
    &:nth-child(4) {
      width: 235px;
      cursor: pointer;
    }
    &:nth-child(5) {
      width: 183px;
    }
    &:nth-child(6) {
      width: 150px;
      cursor: pointer;
    }
  }
`
export const BottomCustomersWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 0;
  color: #9e9e9e;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
`
