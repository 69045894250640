import styled from 'styled-components'
export const FilterWrap = styled.form`
  background-color: #ffffff;
  filter: drop-shadow(0px 5px 10px rgba(129, 129, 129, 0.16));
  border-radius: 16px;
  width: 500px;
  padding: 16px 10px 8px;
  color: #333333;
  div {
    display: flex;
  }
`
export const TitleWrap = styled.div`
  justify-content: space-between;
  margin-bottom: 20px;
  svg {
    width: 20px;
    height: 20px;
    color: #9e9e9e;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
  }
`
export const ButtonWrap = styled.div`
  justify-content: end;
  gap: 30px;
  button {
    border-radius: 8px;
    padding: 8px 15px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:first-child {
      color: #2bb54b;
      border: 1px solid;
    }
    &:last-child {
      background-color: #2bb54b;
      color: #ffffff;
    }
  }
`
export const Itemlist = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  li {
    &:first-child {
      font-size: 20px;
      color: #2bb54b;
      border: none;
    }

    display: flex;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    input {
      border: 1px solid black;
      font-size: 20px;
    }
  }
`
export const DayWrap = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
`
