import { BsCheckLg } from 'react-icons/bs'
import { CheckBoxWrap } from './CheckBox.styled'
import { TData } from 'components/FilterCurrent/types'
import { UseFormSetValue } from 'react-hook-form'

type TProps = {
  value: boolean
  onChange: (value: boolean) => void
  setValue?: UseFormSetValue<TData>
  name?: 'show' | 'hidden'
}
export const Checkbox: React.FC<TProps> = ({
  value,
  onChange,
  setValue,
  name,
}) => {
  const handleChange = () => {
    onChange(!value)
    if (setValue && name && name === 'hidden') {
      setValue('show', false)
    }
    if (setValue && name && name === 'show') {
      setValue('hidden', false)
    }
  }
  return (
    <CheckBoxWrap onClick={handleChange} id={value ? 'checked' : 'uncheked'}>
      {value ? <BsCheckLg /> : ''}
    </CheckBoxWrap>
  )
}
