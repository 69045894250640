import styled from 'styled-components'

export const CategorySettingsList = styled.ul`
  display: flex;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
  li {
    display: flex;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    &#active {
      color: #2bb54b;
      &.up {
        svg {
          rotate: 0deg;
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      rotate: 180deg;
      transition: rotate 300ms;
    }
    &:nth-child(1) {
      cursor: pointer;
      padding: 0 10px 0 0;
      width: 415px;
    }
    &:nth-child(2) {
      width: 284px;
      height: 60px;
      cursor: pointer;
    }
    &:nth-child(3) {
      width: 293px;
      height: 60px;
      cursor: pointer;
    }
    &:nth-child(4) {
      cursor: pointer;
    }
  }
`
export const BottomCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 0;
  color: #9e9e9e;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
`

export const SelectLimitCategory = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 72px;
  height: 36px;
  margin: 0 10px;
  outline: none;
  background-color: #ffffff;
  transition: border-color 300ms;
  :hover,
  :focus {
    border-color: #2bb54b;
  }
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  padding: 10px 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" fill="green"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  padding-right: 1.5em;
  &::before {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000000;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translate(0, -50%) translateX(-10px);
  }
`
