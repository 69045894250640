import styled from 'styled-components'
export const DeleteCustomer = styled.button`
  color: #dd1919;
  position: absolute;
  top: -143px;
  right: 0px;
  width: 124px;
  height: 44px;
  font-size: 14px;
  margin: 24px 140px 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #dd1919;
  background-color: transparent;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`
