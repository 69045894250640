import { createAction } from '@reduxjs/toolkit'
import { customerActions } from './reducers'
export const allCustomersAction = {
    ...customerActions,
    getCustomerAction: createAction<{
  limit: string
  page: number
  name?: string
  sortBy?: string
      order?: string
      startDate?: string
  endDate?:string
    }>('customerState/getCustomerAction'),
  createCustomerAction: createAction<FormData>('customerState/createCustomerAction'),
  getUserAction: createAction<string>('customerState/getUserAction'),
  editCustomerAction: createAction<{ id: string, data: FormData }>('customerState/editCustomerAction'),
    deleteCustomerAction:createAction<string>('customerState/deleteCustomerAction')
}