import styled from 'styled-components'
export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #2bb54b;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  &#checked {
    background-color: #2bb54b;
  }
  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
  }
`
