import { call, put, takeLatest } from 'redux-saga/effects'
import { loginAdmin } from './api'
import { loginAdminAction } from './action'
import { apiPrivate } from "http/http";
import { toast } from 'react-toastify'
import { TPostAuthAdminResponse } from './types'
import { t } from 'i18next'
const { postAuthCredentionalRequest, authFulfilled, authError } =
  loginAdminAction

function* workAuth({
  payload: { email, password },
}: ReturnType<typeof postAuthCredentionalRequest>) {
  try {
    const response:TPostAuthAdminResponse = yield call(loginAdmin, {
      email,
      password,
    })

    if (response) {
      apiPrivate.defaults.headers.common.Authorization = `Bearer ${response.token}`
      yield put(authFulfilled(response));

    } 
  } catch (error) {
   toast.error(t('errorsInput.authError'))

    yield put(authError())
  }
}
export function* authWatcher() {
  yield takeLatest(postAuthCredentionalRequest, workAuth)
}
