import { call, put, takeLatest } from 'redux-saga/effects'
import { TGetCategory,TOneCategory } from './types';
import { toast } from 'react-toastify';
import { store } from 'store/store';
import { allCategoryActions } from './action';
import { t } from 'i18next';
import { getCategory,createCategory,getCurrentCategory ,editCategory,deleteCategoryRequest} from './api';
const {
    changeLoading,currentCategory,deleteCategory,deleteCategoryAction,editCategoryAction,currentCategoryById, categoryList, getCategoryList, categoryError,createCategoryAction
} = allCategoryActions;

   
function* getCategoryWorker({payload}:ReturnType< typeof getCategoryList>) {
  yield put(changeLoading(true))
  try {
    const response: TGetCategory = yield call(getCategory,payload);
  yield put (categoryList(response))    
    
  } catch (error) {
     yield put(categoryError(true))
  }
   }

function* createCategoryWorker({payload}:ReturnType<typeof createCategoryAction>) {
 yield put(categoryError(false))
  yield put(changeLoading(true))
  const{limit,page}=store.getState().categoryState
  try {
    yield call(createCategory, payload)
    yield put(getCategoryList({
        page,
        limit,
        sortBy: 'title',
        order: '1',
      }))
   toast.success(t('errorsInput.createdSuccess'))
    yield put(changeLoading(false))
    
  } catch (error) {
    yield put(categoryError(true))
     toast.error(t('errorsInput.toastError'))
  }
}


function* currentCategoryWorker({ payload }: ReturnType<typeof currentCategory>) {
  yield put(changeLoading(true))
  try {
    const response: TOneCategory = yield call(getCurrentCategory, payload);
yield put (currentCategoryById(response))
  } catch (error) {
      yield put(changeLoading(false))
    yield put(categoryError(true))
    
  }
}
function* editCategoryWorker({ payload }: ReturnType<typeof editCategoryAction>) {
  yield put(changeLoading(true))
  try {
    yield call(editCategory, payload)
     toast.success(t('errorsInput.changedSuccess'))
      yield put(changeLoading(false))
  } catch (error) {
    yield put(categoryError(true))
    toast.error(t('errorsInput.toastError'))
  }
}

function* deleteCategoryWorker({ payload }:ReturnType<typeof deleteCategoryAction>) {
  yield put(changeLoading(true))
  try {
    const response: number = yield call(deleteCategoryRequest, payload)    
    if(response=== 200){
      yield put(deleteCategory(payload))
      toast.success(t('errorsInput.deleteSuccess'))
    } 
  }
  catch (error) {
    yield put(categoryError(true))
    toast.error(t('errorsInput.canNotDelete'))    


  }

}
    export function* categoryWatcher() {
      yield takeLatest(getCategoryList, getCategoryWorker)
      yield takeLatest(createCategoryAction, createCategoryWorker)
      yield takeLatest(currentCategory, currentCategoryWorker)
      yield takeLatest(editCategoryAction, editCategoryWorker)
      yield takeLatest(deleteCategoryAction,deleteCategoryWorker)
   }


