import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  color: #333333;
  height: 44px;
  margin: 28px 0 26px;
  p {
    display: flex;
    height: 44px;
    align-items: center;
  }
  div {
    &.ant-spin {
      .ant-spin-dot {
        i {
          background-color: #2bb54b;
        }
      }
    }
  }
`
export const LinkCurrentPage = styled(Link)`
  background-color: #2bb54b;
  color: #ffffff;
  width: 111px;
  height: 44px;
  border-radius: 8px;
  line-height: 1.43;
  font-size: 14px;
  svg {
    width: 20px;
    height: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const InputCurrentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 12px;
  align-items: center;
`
export const UnderInputCurrentWrapper = styled.div`
  display: flex;
  gap: 32px;
  padding: 0 20px;
`
export const ElInputWrap = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: 8px;
  transition: border-color 250ms;
  position: relative;

  :nth-child(1) {
    gap: 9.4px;

    svg {
      transition: color 300ms;
      width: 20px;
      height: 20px;
      color: #9e9e9e;
      position: absolute;
      left: 12px;
      top: 12px;
    }
    label {
      position: relative;
    }
    input {
      transition: border-color 300ms;
      border-radius: 8px;
      border: 1px solid #e1e1e1;
      padding: 12px 12px 12px 41px;
      outline: none;
      height: 44px;
      border: 1px solid #e1e1e1;
      width: 315px;
      ::placeholder {
        color: #9e9e9e;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.43;
      }
      &:hover,
      &:focus {
        border: 1px solid #2bb54b;
        + svg {
          color: #2bb54b;
        }
      }
    }
  }
  :nth-child(2) {
    .data__range__picker {
      height: 44px;
      width: 300px;
      border-radius: 8px;
      &.ant-picker:hover,
      &.ant-picker-focused {
        border-color: #2bb54b;
        box-shadow: none;
        .ant-picker-active-bar {
          background-color: #2bb54b;
        }
      }
    }
    border: none;
  }

  :nth-child(3) {
    border: 1px solid #e1e1e1;

    justify-content: center;
    gap: 9.4px;
    width: 150px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    transition: color 250ms, border-color 250ms;
    cursor: pointer;
    &:hover {
      border-color: #2bb54b;
      color: #2bb54b;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`
export const DownloadWrap = styled.button`
  padding: 0 20px;
  display: flex;
  border: none;
  cursor: pointer;
  line-height: 1.15;
  font-size: 16px;
  background-color: transparent;
  align-items: center;
  color: #2bb54b;
  display: flex;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`
export const SettingsFilterList = styled.ul`
  display: flex;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
`
export const SettingsItem = styled.li`
  display: flex;
  align-items: center;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  &#active {
    color: #2bb54b;
    &.up {
      svg {
        rotate: 0deg;
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    rotate: 180deg;
    transition: rotate 300ms;
  }
  :nth-child(1) {
    width: 60px;
    padding: 0 10px 0 0;
  }
  :nth-child(2) {
    cursor: pointer;
    width: 244px;
  }
  :nth-child(3) {
    cursor: pointer;
    width: 144px;
  }
  :nth-child(4) {
    cursor: pointer;

    width: 135px;
  }
  :nth-child(5) {
    cursor: pointer;
    width: 240px;
  }
  :nth-child(6) {
    width: 206px;
  }
  :nth-child(7) {
    width: 171px;
  }
`
export const SelectLimitPage = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 72px;
  height: 36px;
  margin: 0 10px;
  outline: none;
  background-color: #ffffff;
  transition: border-color 300ms;
  :hover,
  :focus {
    border-color: #2bb54b;
  }
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  padding: 10px 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" fill="green"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  padding-right: 1.5em;
  &::before {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000000;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translate(0, -50%) translateX(-10px);
  }
`
export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 0;
  color: #9e9e9e;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
`
