import { createAction } from '@reduxjs/toolkit'
import { categoryActions } from './reducers'
export const allCategoryActions = {
    ...categoryActions,
  getCategoryList: createAction<{
  limit: string
  page: number
  name?: string
  sortBy?: string
    order?: string
   startDate?: string
  endDate?:string
}>('categoryState/getCategoryList'),
  createCategoryAction: createAction<FormData>('categoryState/createCategory'),
  currentCategory: createAction<string>('categoryState/currentCategory'),
  editCategoryAction: createAction<{ dictates: FormData, id: string }>('categoryState/editCategoryAction'),
  deleteCategoryAction: createAction<string>('categoryState/deleteCategoryAction'),
}
