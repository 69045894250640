import { configureStore } from '@reduxjs/toolkit'
import { establishmentReducer } from './Establishment/reducers'
import createSagaMiddleware from '@redux-saga/core'
import { rootSaga } from './rootSaga'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { loginSliceReducer } from './AdminAuth/reducer'
import { categoryReducer } from './Category/reducers'
import { customerReducer } from './Customers/reducers'
import { bookingsReducer } from './BookingsStore/reducers'
import { rolesReducer } from './Roles'
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['refreshToken','role']
}
const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    authState: persistReducer(persistConfig, loginSliceReducer),
    establishmentState: establishmentReducer,
    categoryState: categoryReducer,
    customerState: customerReducer,
    bookingState: bookingsReducer,
    rolesState:rolesReducer,
  },
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

