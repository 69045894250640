import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPostAuthAdminResponse } from "./types"

type Auth = {
  isLoggedIn: boolean;
  isLoading: boolean;
  error: boolean;
  refreshToken: string;
  refreshing: boolean;
  role: string;
  
};
const initialState: Auth = {
  isLoggedIn: true,
  isLoading: false,
  error: false,
  refreshToken: '',
  refreshing: false,
  role:''
  
};
export const loginSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    authStart(state): void {
      state.isLoading = true;
    },
    authFulfilled(state, {payload}: PayloadAction<TPostAuthAdminResponse>): void {
      state.refreshToken = payload.refresh
      state.isLoading = false;
      state.isLoggedIn = true;
      state.refreshing = false;
      state.role=payload.role
     
    },
    authError(state): void {
      state.error = true;
      state.isLoading = false;
    },
    authExit(state): void {
      state.isLoggedIn = false;
      state.isLoading = false
      state.refreshing = false
      state.refreshToken = '';
      state.role=''
    }, changeRefreshing(state, { payload }: PayloadAction<boolean>) {
      state.refreshing = payload
    }
  }
});

export const loginSliceActions = loginSlice.actions;

export const loginSliceReducer = loginSlice.reducer;
