import { RiDeleteBinLine } from 'react-icons/ri'
import { RxCross2 } from 'react-icons/rx'
import { useAppDispatch } from '../../hooks'
import { allCategoryActions } from '../../store/Category/action'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  DeleteAfterTitle,
  DeleteBtnWrap,
  DeleteTitle,
  DeleteWrap,
  TitleWrap,
} from '../'
type TProps = {
  id: string
  close: () => void
}
export const DeleteCategory: React.FC<TProps> = ({ id, close }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onDeleteClick = () => {
    dispatch(allCategoryActions.deleteCategoryAction(id))
    close()
    navigate('/categories')
  }
  return (
    <DeleteWrap>
      <TitleWrap>
        <DeleteTitle>{t('category.deleteTitle')}</DeleteTitle>
        <button onClick={close}>
          <RxCross2 />
        </button>{' '}
      </TitleWrap>
      <DeleteAfterTitle>{t('category.sure')}</DeleteAfterTitle>
      <DeleteBtnWrap>
        <button onClick={onDeleteClick}>
          <RiDeleteBinLine />
          <p>{t('establishment.delete')}</p>
        </button>
        <button onClick={close}>{t('rolesLast.cancel')}</button>
      </DeleteBtnWrap>
    </DeleteWrap>
  )
}
