// import axios from "axios";
import { apiPrivate } from "http/http";
import { TPostAuthAdminRequest,TPostAuthAdminResponse } from "./types";

export const loginAdmin = async (
  user: TPostAuthAdminRequest
): Promise<{ token: string,refresh:string }> => {
  const response = await apiPrivate.post<TPostAuthAdminResponse>("/api/v1/auth/manager", user);
  return response.data;
};
