import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkfromPending = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LinkLi = styled.li`
  width: 44px;
  height: 44px;
  background-color: #2bb54b;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 65px;
  margin-top: 12px;
  svg {
    display: flex;
    width: 20px;
    height: 20px;
  }
`
