import axios from "axios";
import { store } from "store";
import { loginAdminAction } from "store/AdminAuth";
 const privateInstance = axios.create({
  baseURL: 'https://dev-api.radius.kitg.com.ua/',headers: { 'Accept-Language': 'en-EN' }
})

privateInstance.interceptors.response.use(
  (response) => response,

  async (error) => {
    const{refreshToken}=store.getState().authState
    const originalRequest = error.config;
    if (error.response.status === 401&&!originalRequest._retry) {
      originalRequest._retry = true;
      try {
          const response = await axios.post<{ token: string }>(
        `https://dev-api.radius.kitg.com.ua/api/v1/auth/refresh`,
        {
          refresh: refreshToken,
        },
      )  
       privateInstance.defaults.headers.common.Authorization = `Bearer ${response.data.token}`; originalRequest.headers.Authorization = `Bearer ${response.data.token}` ;
      } catch (error) {
         store.dispatch(loginAdminAction.authExit())
      }
      return await axios(originalRequest);
  
    } 
    return Promise.reject(error)
  }
)
  export const apiPrivate = privateInstance;