import { useForm, SubmitHandler } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { loginAdminAction } from '../../store/AdminAuth/action'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Form,
  LoginTitle,
  Label,
  InputLoginForm,
  WrapInput,
  LoginButton,
  Visiblebutton,
  ErrorMessage,
} from './LoginForm.styled'
import { LoginWrap } from './LoginForm.styled'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
interface IData {
  email: string
  password: string
}
export const LoginForm: React.FC = () => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const fromPage = location.state?.from?.pathname || '/'
  const isLoaggedIn = useAppSelector(state => state.authState.isLoggedIn)

  useEffect(() => {
    isLoaggedIn && navigate(fromPage)
  }, [fromPage, isLoaggedIn, navigate])
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<IData>({ mode: 'onChange' })
  const dispatch = useAppDispatch()
  const onSubmit: SubmitHandler<IData> = (data: IData) => {
    dispatch(loginAdminAction.postAuthCredentionalRequest(data))
  }

  const onHandleVisibleClick = () => {
    setVisiblePassword(prevState => {
      return !prevState
    })
  }

  return (
    <LoginWrap>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <LoginTitle>Login</LoginTitle>
        <WrapInput>
          <div>
            <Label htmlFor="email">Email</Label>
            <InputLoginForm
              className={errors.email && 'error'}
              placeholder="mail@mail.com"
              type="email"
              id="email"
              {...register('email', {
                required: { value: true, message: 'this field is required' },
                minLength: { value: 5, message: 'minimum 5 letters' },
              })}
            />
            <ErrorMessage>
              {errors.email && <p>{errors.email.message}</p>}
            </ErrorMessage>
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <InputLoginForm
              className={errors.password && 'error'}
              placeholder="password"
              type={!visiblePassword ? 'password' : 'text'}
              id="password"
              {...register('password', {
                required: { value: true, message: 'this field is required' },
                minLength: { value: 5, message: 'minimum 5 symbols' },
              })}
            />
            <ErrorMessage>
              {errors.password && <p>{errors.password.message}</p>}
            </ErrorMessage>
          </div>
          <Visiblebutton type="button" onClick={onHandleVisibleClick}>
            {visiblePassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </Visiblebutton>
        </WrapInput>
        <LoginButton type="submit">Login</LoginButton>
      </Form>
    </LoginWrap>
  )
}
