import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TUser, TUserResponse } from './types'
type TCustomerState = {
    customerList: TUser[],
     loading: boolean,
    error: boolean,
    customersCount: number,
    currentCustomer: Partial<TUser>,
    page: number,
    limit: string
}
const initialState: TCustomerState = {
    customerList: [],
    loading: false,
    error: false,
    customersCount:0,
    currentCustomer: {},
    page: 1,
    limit: '10',
}
const customerSlice = createSlice({
    name: 'customerState',
    initialState,
    reducers: {
        changeLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
        changeError: (state, { payload }: PayloadAction<boolean>) => {
            state.error = payload
            state.loading=false
        },
        getCustomerList: (state, { payload }: PayloadAction<TUserResponse>) => {
            state.customerList = payload.models;
            state.customersCount = payload.totalCount;
            state.error = false;
            state.loading = false;
        },
        changePage: (state, { payload }: PayloadAction<number>) => {
            state.page = payload
        },
        changeLimit: (state, { payload }: PayloadAction<string>) => {
            state.limit = payload;
        },
        getCurrentUser: (state, { payload }: PayloadAction<TUser>) => {
            state.currentCustomer = payload;
            state.loading = false;
            state.error = false;
        },
        deleteUser: (state, { payload }: PayloadAction<string>) => {
            const indx = state.customerList.findIndex(item => item.id === payload)
            state.customerList.splice(indx,1)
        }
    }
})
export const customerActions = customerSlice.actions

export const customerReducer = customerSlice.reducer