import styled from 'styled-components'
export const MainIfoRolesWrap = styled.div`
  .roles__info,
  .roles__history {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`
export const DeleteRoleBtn = styled.button`
  &.delete {
    position: absolute;
    top: -183px;
    right: 110px;
    width: 124px;
    height: 44px;
    border: 1px solid #dd1919;
    border-radius: 8px;
    color: #dd1919;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 6px;
    outline: none;
    padding: 1px 8px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    :hover {
      background-color: transparent;
    }
  }
`
export const SortingList = styled.ul`
  display: flex;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
  li {
    display: flex;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    height: 60px;
    &#active {
      color: #2bb54b;
      &.up {
        svg {
          rotate: 0deg;
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      rotate: 180deg;
      transition: rotate 300ms;
    }

    &:nth-child(1) {
      padding: 0 10px 0 0;
      width: 67px;
    }
    &:nth-child(2) {
      width: 291px;
    }
    &:nth-child(3) {
      width: 225px;
    }
    &:nth-child(4) {
      width: 265px;
    }
    &:nth-child(5) {
      width: 197px;
    }
  }
`
export const EstablishmentList = styled.ul`
  li {
    border-bottom: 1px solid #ededed;
    padding: 12px 20px;
    height: 68px;
    display: flex;
    ul {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      display: flex;
      align-items: center;
      li {
        padding-left: 0;
        display: flex;
        align-items: center;

        &:nth-child(1) {
          width: 87px;
          padding: 0 10px 0 0;
          padding-left: 0;
        }
        &:nth-child(2) {
          width: 291px;
          img {
            object-fit: cover;
            width: 44px;
            height: 44px;
            margin-right: 8px;
            border-radius: 8px;
          }
          p {
            display: flex;
            height: 100%;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
          }
        }
        &:nth-child(3) {
          width: 225px;
        }
        &:nth-child(4) {
          width: 265px;
        }
        &:nth-child(5) {
          width: 197px;
          padding-right: 0;
          div {
            margin-right: 8px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            &#published {
              background-color: #19dda2;
            }
            &#unpublished {
              background-color: #dd1919;
            }
            &#pending {
              background-color: #f59e0b;
            }
          }
        }
        &:nth-child(6) {
          width: 265px;
          cursor: pointer;
          div {
            border-radius: 8px;
            color: #ffffff;
            width: 44px;
            height: 44px;
            background-color: #2bb54b;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
`
