import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TModelsCategory,TGetCategory,TOneCategory } from './types'

type TApiResponse = {
    categoryList: TModelsCategory,
    loading: boolean,
    error: boolean;
    categoryCount: number;
    currentCategoryInfo: Partial<TOneCategory>;
    page: number,
    limit: string;
}

const initialState:TApiResponse = {
    categoryList: [],
    loading: false,
    error: false,
    categoryCount: 0,
    currentCategoryInfo: {},
    page: 1,
    limit:'10'
}
export const categorySlice = createSlice({
    name: 'categoryState',
    initialState,
    reducers: {
        categoryList(state, { payload }: PayloadAction<TGetCategory>) {            
            state.categoryList = payload.models
            state.categoryCount=payload.totalCount
    state.loading = false
    state.error=false
        }, changeLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },  categoryError(state,{payload}: PayloadAction<boolean>): void {
    state.error = payload
    state.loading=false
        },
        currentCategoryById(state, action: PayloadAction<TOneCategory>) {
            state.currentCategoryInfo=action.payload
            state.loading = false;
            state.error = false;
       },
        deleteCategory(state, { payload }: PayloadAction<string>) {
            const indxItem = state.categoryList.findIndex(
        item => item.id === payload,
      )
            state.categoryList.splice(indxItem, 1)
            state.loading = false;
            state.error = false;
        },
        changeCategoryPage:(state, { payload }: PayloadAction<number>) => {
            state.page = payload;
        },
        changeLimit: (state, { payload }: PayloadAction<string>) => {
            state.limit = payload;
        }
    }

})
export const categoryActions = categorySlice.actions

export const categoryReducer = categorySlice.reducer