import { apiPrivate } from 'http/http'
import { TResponse, TDeleteSuccess, TEstablishmentData } from './types'
type TrequestBodyreject = { id: string | undefined; reason: string }

export const getEstablishment = async (data: {
  limit: string
  page: number
  status: string[]
  title?: string
  sortBy?: string
  order?: string
  day?: string[]
  price?: string[]
  show?: string
  startDate?: string
  endDate?: string
  manager?: string
}): Promise<TResponse> => {
  const response = await apiPrivate.get(`api/v1/admin/establishments`, {
    params: data,
  })

  return response.data
}
export const deleteEstablishment = async (id: string | undefined) => {
  const response = await apiPrivate.delete<TDeleteSuccess>(
    `api/v1/admin/establishments/${id}`,
  )
  return response.data
}

export const approveItem = async (id: string) => {
  await apiPrivate.patch(`/api/v1/admin/establishments/${id}/publish`)
}
export const rejectItem = async ({ id, reason }: TrequestBodyreject) => {
  await apiPrivate.patch(`/api/v1/admin/establishments/${id}/unpublish`, {
    reason,
  })
}
export const createEstablishment = async (formData: FormData) => {
  await apiPrivate.post('/api/v1/admin/establishments', formData)
}
export const getEstablishmentForId = async (id: string) => {
  const response = await apiPrivate.get<TEstablishmentData>(
    `/api/v1/admin/establishments/${id}`,
  )
  return response.data
}
export const editEstablishment = async ({
  data,
  id,
}: {
  data: FormData
  id: string
}) => {
  await apiPrivate.put(`/api/v1/admin/establishments/${id}`, data)
}
