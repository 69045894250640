import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { GlobalStyle } from 'GlobalStyle'
import { useAppSelector } from 'hooks'
import { Layout, PrivateRoute, CustomerEditBooking, EditInfoCustomer } from '..'
import { Dashboard, LoginForm } from 'pages'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { InfoRole, HistoryRole } from 'pages/EditRole'
const ApproveBooking = lazy(() => import('pages/ApproveBooking/ApproveBooking'))
const EditEstablishment = lazy(
  () => import('pages/EditEstablishment/EditEstablishment'),
)
const EstablishmentCurrent = lazy(
  () => import('pages/EstablishmentCurrent/EstablishmentCurrent'),
)
const ApproveEstablishment = lazy(
  () => import('pages/ApproveEstablishment/ApproveEstablishment'),
)
const Bookings = lazy(() => import('pages/Bookings/Bookings'))

const NewEstablishment = lazy(
  () => import('pages/NewEstablishment/NewEstablishment'),
)
const Categories = lazy(() => import('pages/Categories/Categories'))

const EstablishmentPending = lazy(
  () => import('pages/EstablishmentPending/EstablishmentPending'),
)
const Customers = lazy(() => import('pages/Customers/Customers'))

const Roles = lazy(() => import('pages/Roles/Roles'))
const NewCategory = lazy(() => import('pages/NewCategory/NewCategory'))
const EditCategory = lazy(() => import('pages/EditCategory/EditCategory'))
const NewCustomer = lazy(() => import('pages/NewCustomer/NewCustomer'))
const EditCustomer = lazy(() => import('pages/EditCustomer/EditCustomer'))
const NewRoles = lazy(() => import('pages/NewRoles/NewRoles'))
const EditRole = lazy(() => import('pages/EditRole/EditRole'))
export const AppRoutes: React.FC = () => {
  const { refreshing } = useAppSelector(state => state.authState)

  return (
    <>
      {!refreshing ? (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute component={<Layout />} redirectTo="/login" />
              }>
              <Route
                index
                element={
                  <PrivateRoute component={<Dashboard />} redirectTo="/login" />
                }
              />
              <Route
                path="/establishments"
                element={
                  <PrivateRoute
                    component={<EstablishmentCurrent />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/new-establishment"
                element={
                  <PrivateRoute
                    component={<NewEstablishment />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/edit/:id"
                element={
                  <PrivateRoute
                    component={<EditEstablishment />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/pending"
                element={
                  <PrivateRoute
                    component={<EstablishmentPending />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/pending/:id"
                element={
                  <PrivateRoute
                    component={<ApproveEstablishment />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/bookings"
                element={
                  <PrivateRoute component={<Bookings />} redirectTo="/login" />
                }
              />
              <Route
                path="/booking/:id"
                element={
                  <PrivateRoute
                    component={<ApproveBooking />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/categories"
                element={
                  <PrivateRoute
                    component={<Categories />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/edit-category/:id"
                element={
                  <PrivateRoute
                    component={<EditCategory />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/new-category"
                element={
                  <PrivateRoute
                    component={<NewCategory />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/customers"
                element={
                  <PrivateRoute component={<Customers />} redirectTo="/login" />
                }
              />
              <Route
                path="/new-customer"
                element={
                  <PrivateRoute
                    component={<NewCustomer />}
                    redirectTo="/login"
                  />
                }
              />
              <Route
                path="/edit-customer/:id"
                element={
                  <PrivateRoute
                    component={<EditCustomer />}
                    redirectTo="/login"
                  />
                }>
                <Route
                  path="edit-info"
                  element={
                    <PrivateRoute
                      component={<EditInfoCustomer />}
                      redirectTo="/login"
                    />
                  }
                />{' '}
                <Route
                  path="edit-customer-bookings"
                  element={
                    <PrivateRoute
                      component={<CustomerEditBooking />}
                      redirectTo="/login"
                    />
                  }
                />
              </Route>
              <Route
                path="/roles"
                element={
                  <PrivateRoute component={<Roles />} redirectTo="/login" />
                }
              />
              <Route
                path="/new-roles"
                element={
                  <PrivateRoute component={<NewRoles />} redirectTo="/login" />
                }
              />
              <Route
                path="/edit-roles/:id"
                element={
                  <PrivateRoute component={<EditRole />} redirectTo="/login" />
                }>
                {' '}
                <Route
                  path="roles-info"
                  element={
                    <PrivateRoute
                      component={<InfoRole />}
                      redirectTo="/login"
                    />
                  }
                />{' '}
                <Route
                  path="roles-history"
                  element={
                    <PrivateRoute
                      component={<HistoryRole />}
                      redirectTo="/login"
                    />
                  }
                />
              </Route>
            </Route>
            <Route path="/login" element={<LoginForm />} />
          </Routes>
          <GlobalStyle />
          <ToastContainer autoClose={3500} toastClassName="toast" />
        </>
      ) : (
        <div style={{ position: 'absolute', top: '40px', left: '50vw' }}>
          Loading
        </div>
      )}
    </>
  )
}
