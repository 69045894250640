import {
  DeleteWrap,
  DeleteBtnWrap,
  DeleteTitle,
  DeleteAfterTitle,
  TitleWrap,
} from './FormDelete.styled'
import { allEstablishmentActions } from 'store/Establishment'
import { useAppDispatch } from 'hooks'
import { RxCross2 } from 'react-icons/rx'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

type TProps = {
  id: string
  close: () => void
}

export const FormDelete: React.FC<TProps> = ({ id, close }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onDeleteClick = async () => {
    dispatch(allEstablishmentActions.deleteItemRequest(id))
    close()
    navigate('/establishments')
  }
  return (
    <DeleteWrap>
      <TitleWrap>
        <DeleteTitle>{t('deleteEstabl.title')}</DeleteTitle>
        <button onClick={close}>
          <RxCross2 />
        </button>
      </TitleWrap>
      <DeleteAfterTitle>{t('deleteEstabl.sure')}</DeleteAfterTitle>
      <DeleteBtnWrap>
        <button onClick={onDeleteClick}>
          <RiDeleteBinLine />
          <p>{t('establishment.delete')}</p>
        </button>
        <button onClick={close}>{t('rolesLast.cancel')}</button>
      </DeleteBtnWrap>
    </DeleteWrap>
  )
}
