// import ReactExport from 'react-export-excel'
import { languageAudit, selectDateLocal } from 'locale'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { TEstablishmentData } from 'store/Establishment'
import { format } from 'date-fns'
import { t } from 'i18next'
import { TModelsCategory } from 'store/Category'
import { TUser } from 'store/Customers/types'
import { TBokingsModel } from 'store/BookingsStore/types'
import { TModels } from 'store/Roles'

export const exportFileEstablishment = (
  data: TEstablishmentData[],
  fileName: string,
) => {
  const language = localStorage.getItem('language')
  const row = data.map(item => {
    return {
      '№': item.index,
      Establishment: item.title[languageAudit(language || 'en') || 0].value,
      Categoty: item.category.title[languageAudit(language || 'en') || 0].name,
      Date: format(new Date(item.createdAt), 'MMM d, YYY, p', {
        locale: selectDateLocal(language),
      }),
      Location: item.address[0].value,
      Status: t(`status.${item.status}`),
    }
  })
  const worksheet = XLSX.utils.json_to_sheet(row)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, fileName + '.xlsx')
}

export const exportFileCategory = (data: TModelsCategory, fileName: string) => {
  const language = localStorage.getItem('language')
  const row = data.map(item => {
    return {
      Categoty: item.title[languageAudit(language || 'en') || 0].name,
      Establishments: item.establishments,
      Status: item.show ? t(`showFilter`) : t(`hiddenFilter`),
    }
  })
  const worksheet = XLSX.utils.json_to_sheet(row)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, fileName + '.xlsx')
}

export const exportFileCustomers = (data: TUser[], fileName: string) => {
  const language = localStorage.getItem('language')
  const row = data.map(item => {
    return {
      '№': item.index,
      Customer: `${item.name} ${item.surname}`,
      Phone: item.phone,
      Registered: format(new Date(item.createdAt), 'MMM d, YYY, p', {
        locale: selectDateLocal(language),
      }),
      Bookings: item.bookings,
      Status: item.blocked ? t(`blocked`) : t(`active`),
    }
  })
  const worksheet = XLSX.utils.json_to_sheet(row)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, fileName + '.xlsx')
}

export const exportFileBookings = (data: TBokingsModel[], fileName: string) => {
  const language = localStorage.getItem('language')
  const row = data.map(item => {
    return {
      '№': item.index,
      Establishment:
        item.establishment.title[languageAudit(language || 'en') || 0].value,
      Date: format(new Date(item.date), 'MMM d, YYY, p', {
        locale: selectDateLocal(language),
      }),
      Customer: `${item.user.name} ${item.user.surname}`,
      Persond: item.persons,
      Status: t(`bookingStatus.${item.status}`),
    }
  })
  const worksheet = XLSX.utils.json_to_sheet(row)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, fileName + '.xlsx')
}

export const exportFileRoles = (data: TModels[], fileName: string) => {
  const language = localStorage.getItem('language')
  const row = data.map(item => {
    return {
      '№': item.index,
      Name: item.name,
      Date: format(new Date(item.createdAt), 'MMM d, YYY, p', {
        locale: selectDateLocal(language),
      }),
      Email: item.email,
      Role: item.role,
      Created: item.establishmentsCount,
    }
  })
  const worksheet = XLSX.utils.json_to_sheet(row)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, fileName + '.xlsx')
}
