import { BiPencil } from 'react-icons/bi'
import { CustomerCharItem, LinkUserEdit, CharList } from './CustomerItem.styled'
import { format } from 'date-fns'
import { selectDateLocal } from 'locale'
import { useTranslation } from 'react-i18next'

type TProps = {
  id: string
  index: number
  name: string
  phone: string
  registration: string
  bookings: number
  status: string
  avatar?: string | null
  surname: string
}
const firstPartImg = 'https://dev-api.radius.kitg.com.ua/public/avatar/'
const defaultImg = './default-user-image.png'
export const CustomerItem: React.FC<TProps> = ({
  index,
  id,
  name,
  phone,
  registration,
  bookings,
  status,
  avatar,
  surname,
}) => {
  const language = localStorage.getItem('language')
  const { t } = useTranslation()

  return (
    <CustomerCharItem>
      <CharList>
        <li>{index}</li>
        <li>
          <img
            alt={name}
            src={
              avatar && avatar !== 'null' ? firstPartImg + avatar : defaultImg
            }
          />
          <p>{`${name} ${surname}`}</p>
        </li>
        <li>{phone}</li>
        <li>
          {format(new Date(registration), 'MMM d, YYY', {
            locale: selectDateLocal(language),
          })}{' '}
          {t('at')}{' '}
          {format(new Date(registration), 'p', {
            locale: selectDateLocal(language),
          })}
        </li>
        <li>{bookings}</li>
        <li>
          <div id={status.toLowerCase()}></div>
          {t(`customerStatus.${status}`)}
        </li>
        <li>
          <LinkUserEdit to={`/edit-customer/${id}`}>
            {' '}
            <BiPencil />
          </LinkUserEdit>
        </li>
      </CharList>
    </CustomerCharItem>
  )
}
