import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
export const MainNewCustomerWrap = styled.div`
  height: 44px;
  div {
    &.new__customer {
      padding: 0;
    }
  }
`
export const HeadingCustomer = styled(Link)`
  margin: 28px 0 26px;
  display: flex;
  height: 44px;
  width: 260px;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  svg {
    width: 24px;
    height: 24px;
    color: #2bb54b;
  }
`
export const InfoLink = styled(NavLink)`
  position: relative;
  height: 45px;
  width: 181px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 16px;
  background-color: #ffffff;
  color: #9e9e9e;
  border-bottom: 2px solid #ededed;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  &.active {
    border: none;
    color: #2bb54b;
    &::after {
      content: '';
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #2bb54b;
      width: 181px;
      height: 2px;
      bottom: 0px;
    }
  }
`
export const BookingNewCustomer = styled(NavLink)`
  position: relative;

  height: 45px;
  width: 181px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 16px;
  background-color: #ffffff;
  color: #9e9e9e;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  border-bottom: 2px solid #ededed;
  &.active {
    border: none;
    color: #2bb54b;
    &::after {
      content: '';
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #2bb54b;
      width: 181px;
      height: 2px;
      bottom: 0;
    }
  }
`
export const LinkWrap = styled.div`
  display: flex;
`
export const CustomerInfoWrap = styled.div`
  position: relative;
  div {
    &.new__customer {
      padding-bottom: 20px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`
export const CustomerForm = styled.form`
  display: flex;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
`
export const SubmitCustomerBtn = styled.button`
  position: absolute;
  width: 102px;
  height: 44px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  background-color: #2bb54b;
  color: #ffffff;
  top: -80px;
  right: 0;
  &.edit {
    top: -120px;
  }
`

export const LeftWrapCustomer = styled.div`
  padding: 20px;
  height: 799px;
  width: 752px;
  border-right: 1px solid #ededed;
  p {
    margin-bottom: 24px;
    &.date__birthday {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
  .ant-picker:hover,
  .ant-picker:active,
  .ant-picker-focused {
    border-color: #2bb54b;
  }
  .photo__title {
    font-size: 14px;
    margin: 24px 0 0;
  }
  .photo__label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    position: relative;
    width: 219px;
    height: 219px;
    padding: 10px 12px;
    background: #d8fde4;
    border: 1px dashed #2bb54b;
    border-radius: 50%;
    color: #2bb54b;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-bottom: 8px;
    }
  }
`
export const InfoCustomerWrap = styled.div`
  display: grid;
  gap: 28px;
  grid-template-columns: 342px 342px;
  margin-bottom: 28px;
  label {
    position: relative;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      width: 200px;
      p {
        margin-left: 8px;
        color: #9e9e9e;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .phone__wrap {
      margin-top: 8px;
    }
    .phone__input {
      width: 342px;
      height: 44px;
      border: 1px solid #e1e1e1;
      border-radius: 8px;
      outline: none;
      :focus,
      :hover {
        border-color: #2bb54b;
      }
      transition: border-color 300ms;
    }
  }
  input {
    margin-top: 8px;
    width: 342px;
    height: 44px;
    padding: 10px 12px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    outline: none;
    :focus,
    :hover {
      border-color: #2bb54b;
    }
    transition: border-color 300ms;
  }
`
export const ErrorCustomerMessage = styled.div`
  position: absolute;
  top: 70px;
  left: 8px;
  p {
    color: red;
    font-weight: 400;
  }
`
export const RightWrapCustomer = styled.div`
  padding: 20px;
  p {
    margin-bottom: 24px;
  }
  div {
    label {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      select {
        margin-top: 8px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 342px;
        height: 44px;
        outline: none;
        background-color: #ffffff;
        transition: border-color 300ms;
        :hover,
        :focus {
          border-color: #2bb54b;
        }
        border: 1px solid rgb(225, 225, 225);
        border-radius: 8px;
        padding: 10px 12px;
        background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" fill="green"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 0.5em center;
        padding-right: 1.5em;
        &::before {
          content: '';
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #000000;
          position: absolute;
          top: 50%;
          right: 1em;
          transform: translate(0, -50%) translateX(-10px);
        }
      }
    }
  }
`
export const ImageWrap = styled.div`
  position: relative;
  button {
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 190px;
    background-color: transparent;
    outline: none;
    border: none;
    svg {
      width: 20px;
      height: 20px;
      color: #dd1919;
    }
  }
  .image__mask {
    border-radius: 50%;
  }
`
