import styled from 'styled-components'
import { animated } from '@react-spring/web'
export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(85, 85, 85, 0.3);
  z-index: 120;
  transition: scale 300ms;
`
export const ModalWindow = styled(animated.div)`
  position: absolute;
  transition: all 300ms;
  max-height: calc(100vh - 24px);
`
