import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
type TProps = {
  component: React.ReactNode | any
  redirectTo: string
}
export const PrivateRoute = ({
  component: Component,
  redirectTo: addres,
}: TProps) => {
  const location = useLocation()
  const { isLoggedIn } = useAppSelector(state => state.authState)
  return !isLoggedIn ? (
    <Navigate to={addres} state={{ from: location }} />
  ) : (
    Component
  )
}
