import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import en from './locale/en.json'
import ru from './locale/ru.json'
const language =localStorage.getItem('language');
const resources = {
    en: {
        translation:en
    },
    ru: {
        translation:ru
    }
}

i18n.use(initReactI18next).init({
    lng: language?language:'en', resources,
 
})
export default i18n