import { PageWrapper, Modal, FormDeleteRole } from 'components'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SelectNewEstablishment } from 'pages/NewEstablishment/NewEstablishment.styled'
import {
  ErrorMessageRoles,
  NewRolesForm,
  NewRolesFormWrap,
} from 'pages/NewRoles/NewRoles.styled'
import { MainIfoRolesWrap, DeleteRoleBtn } from './EditRole.styled'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { allRolesAction } from 'store/Roles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiDeleteBinLine } from 'react-icons/ri'
type TForm = {
  name: string
  surname: string
  role: string
  email: string
  bonus: number
  password: '' | string
  roleWho: string
}

export const InfoRole: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const minLength = 3
  const minPasswordLength = 8
  const { isOpen, open, close } = useToggle(false)
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
  const { currentRole, error, loading } = useAppSelector(
    state => state.rolesState,
  )
  const { id } = useAppSelector(state => state.rolesState.currentRole)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TForm>({ mode: 'all' })
  const onSubmit = (data: TForm) => {
    const value = {
      name: `${data.name} ${data.surname}`,
      email: data.email,
      password: data.password ? data.password : undefined,
      role: data.role,
      bonusPerEstablishment: +data.bonus,
    }
    id && dispatch(allRolesAction.editRoleAction({ data: value, id }))

    !error && !loading && navigate('/roles')
  }
  useEffect(() => {
    currentRole.name && setValue('name', currentRole.name.split(' ')[0])
    currentRole.name && setValue('surname', currentRole.name.split(' ')[1])
    currentRole.role && setValue('role', currentRole.role)
    currentRole.email && setValue('email', currentRole.email)
    currentRole.bonusPerEstablishment &&
      setValue('bonus', currentRole.bonusPerEstablishment)
  }, [currentRole, setValue])
  return (
    <MainIfoRolesWrap>
      <PageWrapper className="roles__info">
        {isOpen && (
          <Modal closeModal={close}>
            <FormDeleteRole close={close} id={id} />
          </Modal>
        )}
        <NewRolesFormWrap>
          <p>{t('establishment.basicInfo')}</p>

          <NewRolesForm onSubmit={handleSubmit(onSubmit)}>
            <button className="edit__info__role" type="submit">
              {t('establishment.update')}
            </button>
            <DeleteRoleBtn className="delete" type="button" onClick={open}>
              <RiDeleteBinLine />
              {t('establishment.delete')}
            </DeleteRoleBtn>
            <label>
              {t('name')}
              <input
                {...register('name', {
                  required: t('errorsInput.required')!,
                  minLength: {
                    value: minLength,
                    message: t('errorsInput.minLetter', { minLength }),
                  },
                })}
              />{' '}
              <ErrorMessageRoles>
                {errors?.name && <p>{errors.name.message}</p>}
              </ErrorMessageRoles>
            </label>
            <label>
              {t('surname')}
              <input
                {...register('surname', {
                  required: t('errorsInput.required')!,
                  minLength: {
                    value: minLength,
                    message: t('errorsInput.minLetter', { minLength }),
                  },
                })}
              />
              <ErrorMessageRoles>
                {errors?.surname && <p>{errors.surname.message}</p>}
              </ErrorMessageRoles>
            </label>
            <label>
              {t('rolesLast.role')}
              <SelectNewEstablishment {...register('role')}>
                <option value="admin">Admin</option>
                <option value="scout">Scout</option>
                <option value="manager">Manager</option>
              </SelectNewEstablishment>
            </label>
            <label>
              {t('rolesLast.bonus')}
              <input
                type="number"
                min={1}
                max={100}
                {...register('bonus', {
                  required: t('errorsInput.required')!,
                  min: { value: 1, message: t('errorsInput.minimumNumber') },
                })}
              />{' '}
              <ErrorMessageRoles>
                {errors?.bonus && <p>{errors.bonus.message}</p>}
              </ErrorMessageRoles>
            </label>
            <label>
              E-mail
              <input
                type="email"
                {...register('email', { required: t('errorsInput.required')! })}
              />{' '}
              <ErrorMessageRoles>
                {errors?.email && <p>{errors.email.message}</p>}
              </ErrorMessageRoles>
            </label>
            <label>
              {t('rolesLast.password')}
              <input
                placeholder={t('rolesLast.enterNewPasssword')!}
                {...register('password', {
                  minLength: {
                    value: minPasswordLength,
                    message: t('errorsInput.minPassword', {
                      minPasswordLength,
                    }),
                  },
                })}
                type={!visiblePassword ? 'password' : 'text'}
              />{' '}
              <ErrorMessageRoles>
                {errors?.password && <p>{errors.password.message}</p>}
              </ErrorMessageRoles>
              {visiblePassword ? (
                <AiOutlineEyeInvisible
                  onClick={() => setVisiblePassword(prev => !prev)}
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setVisiblePassword(prev => !prev)}
                />
              )}
              <ErrorMessageRoles>
                {errors?.password && <p>{errors.password.message}</p>}
              </ErrorMessageRoles>
            </label>
          </NewRolesForm>
        </NewRolesFormWrap>
      </PageWrapper>
    </MainIfoRolesWrap>
  )
}
