import { format } from 'date-fns'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { AiOutlineEye } from 'react-icons/ai'
import { BookingListItem, BookingLink } from './BookingItem.styled'
import { selectDateLocal } from 'locale'
import { useTranslation } from 'react-i18next'

type TProps = {
  index: number
  user: string
  src: string
  establishment: string
  date: string
  persons: number
  id: string
  status: string
}
const firstPartImg =
  'https://dev-api.radius.kitg.com.ua/public/establishment/images/'
export const BookingItem: React.FC<TProps> = ({
  index,
  user,
  src,
  establishment,
  date,
  persons,
  id,
  status,
}) => {
  const language = localStorage.getItem('language')
  const { t } = useTranslation()
  return (
    <BookingListItem>
      <ul>
        <li>{index}</li>
        <li>
          <img alt={establishment} src={firstPartImg + src} />
          <p>{establishment}</p>
        </li>
        <li>
          {format(utcToZonedTime(new Date(date), 'Etc/GMT'), 'MMM d, YYY', {
            locale: selectDateLocal(language),
          })}{' '}
          {t('at')}{' '}
          {format(utcToZonedTime(new Date(date), 'Etc/GMT'), 'p', {
            locale: selectDateLocal(language),
          })}
        </li>
        <li>{user}</li>
        <li>{persons}</li>
        <li>
          <div className={status}></div> {t(`bookingStatus.${status}`)}
        </li>
        <li>
          {
            <BookingLink to={`/booking/${id}`}>
              <AiOutlineEye />
            </BookingLink>
          }
        </li>
      </ul>
    </BookingListItem>
  )
}
