import { call, put, takeLatest } from 'redux-saga/effects'
import { allRolesAction } from './actions'
import { store } from 'store/store';
import { toast } from 'react-toastify';
import { TResponseRoles ,TModels} from './types';
import { getRolesApi,createRolesApi,editRoleApi,getCurrentRoleApi,deleteRoleApi } from './api';
import { t } from 'i18next';
const { getRolesAction,deleteRoleAction,deleteRole,editRoleAction,getCurrentRoleAction,getCurrentRole,createRoleApi,changeLoading,changeError,getRoles } = allRolesAction;

function* getRolesWorker({payload }:ReturnType<typeof getRolesAction>) {
  yield put  (changeLoading(true))
    try {
        const response: TResponseRoles = yield call(getRolesApi,payload)
        yield put (getRoles(response))
    } catch (error) {
        yield put (changeError(true))
    }
}
function* createRoleWorker({ payload }: ReturnType<typeof createRoleApi>) {
    yield put(changeLoading(true))
    const{limit,page}=store.getState().rolesState
    try {
        
        yield call(createRolesApi, payload)
        toast.success(t('errorsInput.createdSuccess'))
         yield put(changeLoading(false))
        yield put(getRolesAction({ limit, page }))
    } catch (error) {
        yield put(changeError(true))
         toast.error(t('errorsInput.toastError'))
    }
}
function* editRoleWorker({ payload }: ReturnType<typeof editRoleAction>) {
       yield put(changeLoading(true))
    const { limit, page } = store.getState().rolesState
    try {
        yield call(editRoleApi, payload)
       toast.success(t('errorsInput.changedSuccess'))
        yield put(changeLoading(false))
        yield put(getRolesAction({ limit, page }))
    } catch (error) {        
        yield put(changeError(true))
       toast.error(t('errorsInput.toastError'))
        
    }
}
function* getCurrentRoleWorker({ payload }: ReturnType<typeof getCurrentRoleAction>) {
      yield put  (changeLoading(true))
    try {
        const response: TModels = yield call(getCurrentRoleApi,payload)
        yield put (getCurrentRole(response))
    } catch (error) {
        yield put (changeError(true))
    }
}
function* deleteRoleWorker({ payload }: ReturnType<typeof deleteRoleAction>) {
    yield put(changeLoading(true))
    try {
         yield call(deleteRoleApi,payload)
        yield put(deleteRole(payload))
          toast.success(t('errorsInput.deleteSuccess'))
    } catch (error) {
        yield put(changeError(true))
         toast.error(t('errorsInput.toastError'))
    }
}


export function* rolesWatcher() {
    yield takeLatest(getRolesAction, getRolesWorker)
    yield takeLatest(createRoleApi, createRoleWorker)
    yield takeLatest(editRoleAction, editRoleWorker)
    yield takeLatest(getCurrentRoleAction, getCurrentRoleWorker)
    yield takeLatest(deleteRoleAction,deleteRoleWorker)
}