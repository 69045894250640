import { call, put, takeLatest } from 'redux-saga/effects'
import { TBookingsResponse,TBokingsModel } from './types';
import { store } from 'store/store'
import { getBookingsApi,cancelBookingApi,approveBookingApi, getCurrentBookingApi, createBookingsApi, changeBookingApi, deleteBookingsApi } from './api';
import { allBookingActions } from './actions'
import { toast } from 'react-toastify';
import { t } from 'i18next';
const { getBookingAction,approveBookingAction,cancelBookingAction,changeBookingAction,createBookingAction, getBookingList,getCurrentBookingAction,deleteBookingAction, getCurrentBooking, deleteBooking, changeLoading, bookingError } = allBookingActions;

function* getBookingWorker({payload}:ReturnType<typeof getBookingAction>) {
yield put(changeLoading(true))
try {
    const response: TBookingsResponse = yield call(getBookingsApi, payload)
    yield put(getBookingList(response))
} catch (error) {
    yield put(bookingError(true))
    }
}

function* currentBookingWorker({ payload }: ReturnType<typeof getCurrentBookingAction>) {
    yield put(changeLoading(true))
    try {
      const response:TBokingsModel=  yield call (getCurrentBookingApi,payload)
        yield put (getCurrentBooking(response))
    } catch (error) {
        yield put(bookingError(true))
    }
} function* deleteBookingWorker({ payload }: ReturnType<typeof deleteBookingAction>) {
    yield put(changeLoading(true))
    try {
        yield call(deleteBookingsApi, payload)
        yield put(deleteBooking(payload))
        toast.success(t('errorsInput.deleteSuccess'))
    } catch (error) {
        toast.error(t('errorsInput.toastError'))
         yield put(bookingError(true))
        
    }
}
function* changeBookingWorker({ payload }: ReturnType<typeof changeBookingAction>) {
    const{limit,page}=store.getState().bookingState
    yield put(changeLoading(true))
    try {
        yield call(changeBookingApi, payload)
        yield put(store.dispatch(getBookingAction({ limit, page })))
        yield put(changeLoading(false))
        toast.success(t('errorsInput.changedSuccess'))
    } catch (error) {
        yield put(bookingError(true))
        toast.error(t('errorsInput.toastError'))
    }
}

function* createBokingWorker({ payload }: ReturnType<typeof createBookingAction>) {
     const{limit,page}=store.getState().bookingState
    yield put(changeLoading(true))
    try {
        yield call(createBookingsApi, payload)
         yield put(store.dispatch(getBookingAction({ limit, page })))
        yield put(changeLoading(false))
        toast.success(t('errorsInput.createdSuccess'))
    } catch (error) {
        yield put(bookingError(true))
        toast.error(t('errorsInput.toastError'))
    }
}
function* approveBookingWorker({ payload }: ReturnType<typeof approveBookingAction>) {
    yield put(changeLoading(true))
    const{limit,page}=store.getState().bookingState
        try {
            yield call(approveBookingApi, payload)
            yield put(store.dispatch(getBookingAction({ limit, page })))
            yield put(changeLoading(false))
            toast.success(t('errorsInput.changedSuccess'))
        } catch (error) {
            yield put(bookingError(true))
            toast.error(t('errorsInput.toastError'))
        }
}
function* cancelBookingWorker({ payload }: ReturnType<typeof cancelBookingAction>) {
        yield put(changeLoading(true))
    const{limit,page}=store.getState().bookingState
        try {
            yield call(cancelBookingApi, payload)
            yield put(store.dispatch(getBookingAction({ limit, page })))
            yield put(changeLoading(false))
            toast.success(t('errorsInput.changedSuccess'))
        } catch (error) {
            yield put(bookingError(true))
            toast.error(t('errorsInput.toastError'))
        }
}

export function* bookingWatcher() {
    yield takeLatest(getBookingAction, getBookingWorker)
    yield takeLatest(getCurrentBookingAction, currentBookingWorker)
    yield takeLatest(deleteBookingAction, deleteBookingWorker)
    yield takeLatest(changeBookingAction, changeBookingWorker)
    yield takeLatest(createBookingAction, createBokingWorker)
    yield takeLatest(approveBookingAction, approveBookingWorker)
    yield takeLatest(cancelBookingAction,cancelBookingWorker)
    
}