import styled from 'styled-components'
export const HeaderStyled = styled.header`
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(129, 129, 129, 0.1);
  height: 64px;
  width: 1440px;
  display: flex;
`
export const LogoWrapper = styled.div`
  min-width: 237px;
  height: 64px;
  box-shadow: 0px 2px 4px rgba(129, 129, 129, 0.1);
  border-bottom: 1px solid #ededed;
  padding: 14px 19px;
`

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
`

export const NotificationWrap = styled.div`
  /* margin-left: auto; */
  svg {
    width: 24px;
    height: 24px;
  }
`
export const LanguageWrap = styled.div`
  margin: 0 24px 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    cursor: pointer;
    width: 35px;

    transition: scale 300ms;
    &.active {
      scale: 1.2;
      color: #2bb54b;
    }
  }
`
