import styled from 'styled-components'

export const BackLink = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #333333;
  font-weight: 600;
  svg {
    color: #2bb54b;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`
export const NewEstablishmentWrap = styled.div`
  position: relative;
  .new__establishment {
    padding: 0;
  }
  .approve__establishment {
    padding: 0;
  }
`
export const SubmitBtn = styled.button`
  position: absolute;
  top: 28px;
  cursor: pointer;
  right: 0;
  min-width: 106px;
  height: 44px;
  background: #2bb54b;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;
  &[disabled] {
    background: #ededed;
    color: #9e9e9e;
  }
`
export const LeftWrap = styled.div`
  padding: 20px;
  width: 752px;
  border-right: 1px solid #ededed;
`
export const FormAdd = styled.form`
  display: flex;
  div {
    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
    }
    &:nth-child(2) {
    }
    p {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 1.4;
      font-weight: 600;
    }
  }
`
export const InputCustom = styled.input`
  border: 1px solid #e1e1e1;
  width: 712px;
  ::placeholder {
    color: #9e9e9e;
  }

  border-radius: 8px;
  height: 45px;
  outline: none;
  padding: 10px 12px;
  margin-top: 8px;
  transition: border-color 300ms;
  &:focus,
  &:hover {
    border-color: #2bb54b;
  }
  &:not(:last-child) {
    margin-bottom: 28px;
  }
  &:last-child {
    margin-bottom: 24px;
  }
  &#shedule {
    width: 220px;
  }
  &#open,
  &#close {
    width: 220px;
    margin: 0;
  }
  &#approveCategory {
    width: 340px;
    margin: 0;
  }
`
export const SelectNewEstablishment = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 342px;
  height: 44px;
  outline: none;
  background-color: #ffffff;
  transition: border-color 300ms;
  :hover,
  :focus {
    border-color: #2bb54b;
  }
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  padding: 10px 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" fill="green"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  padding-right: 1.5em;
  &::before {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000000;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translate(0, -50%) translateX(-10px);
  }
  &#shedule {
    width: 220px;
  }
`
export const SelectWrap = styled.div`
  display: flex;
  gap: 28px;
  margin-bottom: 28px;
  div {
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 8px;
    }
  }
  &#approve {
    margin-bottom: 0;

    div {
      input {
        width: 340px;
      }
    }
  }
`
export const MapWrap = styled.div`
  width: 100%;
  height: 380px;
  border-radius: 8px;
  /* outline: 1px solid green; */
  display: flex;
  align-items: end;
  margin-bottom: 40px;
  position: relative;
`
export const DropWrap = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 40px;
`
export const MediaWrap = styled.div`
  margin-bottom: 24px;
  p {
    &:nth-child(1) {
      margin-bottom: 24px;
    }
    &:nth-child(2) {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 4px;
    }
    &:nth-child(3) {
      color: #9e9e9e;
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 16px;
    }
  }
  label {
    background-color: #d8fde4;
    border: 1px dashed #2bb54b;
    display: block;
    width: 217px;
    height: 122px;
    border-radius: 8px;
    cursor: pointer;
    color: #2bb54b;
    &#error {
      border: 2px dashed #e72020;
    }

    svg {
      width: 20px;
      height: 20px;
    }
    div {
      margin-top: 36px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10.5px;
    }
  }
  div {
    position: relative;
    max-height: 264px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 20px;
    overflow: auto;
    &.hidden {
      display: none;
    }
    img {
      border-radius: 8px;
      object-fit: cover;
    }
    button {
      cursor: pointer;
      width: 37px;
      opacity: 0.6;
      height: 37px;
      display: flex;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      transform: translate(180px);
      border: none;
      padding-bottom: 3px;
      position: absolute;
      color: #ffffff;
      &:hover {
        svg {
          scale: 1.1;
        }
      }
      svg {
        color: #f50909;
        width: 25px;
        height: 25px;
        transition: color 250ms, scale 250ms;
      }
    }
  }
`
export const MoodWrap = styled.div`
  margin-bottom: 24px;
  p {
    &:nth-child(1) {
      margin-bottom: 4px;
    }
    &:nth-child(2) {
      color: #9e9e9e;
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 16px;
    }
  }
  div {
    padding: 0;
    max-height: 330px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 20px;
    label {
      background-color: #d8fde4;
      border: 1px dashed #2bb54b;
      border-radius: 8px;
      display: block;
      padding: 0;
      width: 217px;
      height: 328px;
      cursor: pointer;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10.5px;
        margin-top: 141px;
        color: #2bb54b;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    div {
      position: relative;
      &.hidden {
        display: none;
      }
      img {
        object-fit: cover;
        width: 217px;
        height: 330px;
        border-radius: 8px;
      }
      video {
        border-radius: 8px;
        overflow: hidden;
        width: 217px;
        height: 330px;
        object-fit: cover;
      }
      button {
        cursor: pointer;
        width: 37px;
        opacity: 0.6;
        background-color: transparent;
        height: 37px;
        display: flex;
        padding-bottom: 3px;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        transform: translate(180px);
        border: none;
        position: absolute;
        color: #ffffff;
        &:hover {
          svg {
            scale: 1.1;
          }
        }
        svg {
          color: #f50909;
          width: 25px;
          height: 25px;
          transition: color 250ms, scale 250ms;
        }
      }
    }
  }
`
export const MenuWrap = styled.div`
  p {
    &:nth-child(1) {
      margin-bottom: 4px;
    }
    &:nth-child(2) {
      margin-bottom: 4px;
      color: #9e9e9e;
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 16px;
    }
  }
  div {
    padding: 0;
    max-height: 454px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 20px;
  }
  label {
    background-color: #d8fde4;
    border: 1px dashed #2bb54b;
    border-radius: 8px;
    display: block;
    padding: 0;
    cursor: pointer;
    &#error {
      border: 2px dashed #e72020;
    }
    width: 217px;
    height: 217px;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10.5px;
      margin-top: 85.5px;
      color: #2bb54b;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  div {
    position: relative;
    &.hidden {
      display: none;
    }
    img {
      object-fit: cover;
      width: 217px;
      height: 217px;
      border-radius: 8px;
    }
    video {
      object-fit: cover;
      border-radius: 8px;
      overflow: hidden;
      width: 217px;
      height: 217px;
    }
    button {
      cursor: pointer;
      width: 37px;
      opacity: 0.6;
      height: 37px;
      padding-bottom: 3px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      transform: translate(180px);
      border: none;
      position: absolute;
      color: #ffffff;
      &:hover {
        svg {
          scale: 1.1;
        }
      }
      svg {
        color: #f50909;
        width: 25px;
        height: 25px;
        transition: color 250ms, scale 250ms;
      }
    }
  }
`
export const SheduleWrap = styled.div`
  button {
    cursor: pointer;
    border: none;
    color: #2bb54b;
    background-color: transparent;
    display: flex;
    gap: 8px;
    padding: 12px 0;
    &[disabled] {
      color: gray;
    }
  }
`
export const WorkingDayList = styled.ul`
  display: flex;
  margin-bottom: 24px;
  gap: 25px;
  flex-direction: column;
  li {
    position: relative;
    display: flex;
    gap: 25px;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 8px;
    }
    div {
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 8px;
      }
    }
    select {
      width: 220px;
    }
    input {
      height: 44px;
      width: 220px;
      padding: 10px 12px;
      border-radius: 8px;
      outline: none;
      border: 1px solid #e1e1e1;
      transition: border-color 300ms;
      outline: none;
      &:focus,
      &:hover {
        border-color: #2bb54b;
      }
    }
    button {
      background-color: #d8fde4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      bottom: 48px;
      right: 5px;
      width: 30px;
      height: 30px;
      svg {
        color: #f50909;
        width: 20px;
        height: 20px;
      }
    }
  }
`
export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
`
export const RightWrap = styled.div`
  width: 100%;
`
export const OrganizationWrap = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ededed;
  div {
    &:nth-child(1) {
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
`
export const HighlightsWrap = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ededed;
  p {
    &:nth-child(2) {
      color: #a0a0a0;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
`
export const HighlightsInputsWrap = styled.ul`
  display: flex;
  gap: 28px;
  flex-direction: column;
  li {
    display: flex;
    align-items: center;
    gap: 12px;
    input {
      transition: border-color 300ms;
      :focus,
      :hover {
        border-color: #2bb54b;
      }
      padding: 12px;
      border: 1px solid #e1e1e1;
      width: 306px;
      outline: none;
      border-radius: 8px;
      height: 44px;
    }
    svg {
      width: 24px;
      height: 24px;
      color: #2bb54b;
    }
  }
`
export const ContactsWrap = styled.div`
  padding: 20px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 28px;
    li {
      gap: 8px;
      display: flex;
      flex-direction: column;
      input {
        :hover {
          border-color: #2bb54b;
        }
        :focus {
          border-color: #2bb54b;
        }
        transition: border-color 300ms;
        padding: 12px;
        outline: none;
        border-radius: 8px;
        border: 1px solid #e1e1e1;
      }
    }
  }
  .phone__input {
    margin: 0;
    width: 371px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }
  .phone__dropdown {
    padding: 0px;
    gap: 2px;

    .country {
      font-weight: 600;
      margin: 0;
      padding: 7px 9px;
      display: flex;
      flex-direction: row;
    }
  }
`
export const ErrorMessage = styled.div`
  position: absolute;
  margin-top: 55px;
  margin-left: 8px;
  p {
    &.paragraph__error {
      font-size: 14px;
      color: red;
      font-weight: 400;
    }
  }
  &#drop {
    margin-top: 3px;
  }
  &#photo {
    transform: translate(-20px, 50px);
    p {
      height: 10px;
      margin: 0;
      font-size: 14px;
      color: red;
      font-weight: 400;
    }
  }
  &#menu {
    margin-top: 15px;
    p {
      font-size: 14px;
      margin-top: 70px;
      color: red;
      font-weight: 400;
    }
  }
  &#daysEn {
    bottom: 140px;
  }
  &#open,
  &#close {
    bottom: 40px;
  }
  &#daysRu {
    bottom: 140px;
  }
  &#organization {
    margin-top: 43px;
  }
  &#highlight {
    margin-top: 46px;
  }
  &#field {
    margin-top: 75px;
  }
`
export const SpecificationWrap = styled.div`
  &.error {
    border-color: red;
  }
  width: 711px;
  padding: 4px 8px;
  margin: 8px 0 28px;
  border: 1px solid #e1e1e1;
  min-height: 44px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  input {
    width: 200px;
    border: none;
    outline: none;
  }
  div {
    height: 34px;
    padding: 6px 8px;
    color: #ffffff;
    background-color: #2bb54b;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;

    svg {
      width: 16px;
      height: 16px;
      /* margin-left: 4px; */
      margin-left: 4px;
      cursor: pointer;
    }
    p {
      padding-top: 2px;
      font-weight: 400;
      font-size: 12px;
      /* height: 16px; */
      line-height: 1.33;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
