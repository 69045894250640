import { createAction } from '@reduxjs/toolkit'
import { establishmentActions } from './reducers'
export const allEstablishmentActions = {
  ...establishmentActions,
  getEstablishmentData: createAction<{
    limit: string
    page: number
    status: string[]
    title?: string
     sortBy?: string
    order?: string
    day?: string[]
    price?: string[]
    show?: string
     startDate?: string
    endDate?: string
  manager?:string
  
  }>('establishmentState/fetch'),
  deleteItemRequest: createAction<string>('establishmentState/deleteItem'),
  approveItemRequest: createAction<string>(
    'establishmentState/approveItem',
  ),
  rejectItemRequest: createAction<{ id: string, reason: string }>(
    'establishmentState/rejectItem',
  ),
  getCurrentEstablishment: createAction<string>('establishmentState/getforId'),
  createNewEstablishment: createAction<FormData>('establishmentState/createEstablishment'),
  updateEstablishment: createAction<{ data: FormData, id: string }>('establishmentState/updateEstablishment'),

}
