import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HeaderStyled,
  LogoWrapper,
  HeaderWrap,
  NotificationWrap,
  LanguageWrap,
} from './Header.styled'

import { RiNotification2Line } from 'react-icons/ri'
export const Header: React.FC = () => {
  const language = localStorage.getItem('language')
  const { i18n } = useTranslation()
  const changeLang = (e: MouseEvent<HTMLParagraphElement>) => {
    if (language === e.currentTarget.id) {
      return
    }
    localStorage.setItem('language', e.currentTarget.id)
    i18n.changeLanguage(e.currentTarget.id)
  }
  return (
    <HeaderStyled>
      <LogoWrapper>LOGO</LogoWrapper>
      <HeaderWrap>
        <LanguageWrap>
          <p
            id="en"
            onClick={changeLang}
            className={language === 'en' || !language ? 'active' : ''}>
            EN
          </p>
          <p
            id="ru"
            onClick={changeLang}
            className={language === 'ru' ? 'active' : ''}>
            RU
          </p>
        </LanguageWrap>
        <NotificationWrap>
          <RiNotification2Line />
        </NotificationWrap>
      </HeaderWrap>
    </HeaderStyled>
  )
}
