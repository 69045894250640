import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TResponse, TEstablishmentData,TPriceRange,TWorkingTime } from './types'

type TApiRespose = {
  response: TEstablishmentData[]
  limit: string
  page: number
  pagePending:number
  sortBy: string
  loading: boolean
  error: boolean
  totalCount: number
  filterDay: TWorkingTime[],
  filterPrice:TPriceRange[],
  filterShow: string,
  filterPendingDay: TWorkingTime[],
  filterPendingPrice:TPriceRange[],
  filterPendingShow:string,
  currentEstablishment: TEstablishmentData |null
}

const initialState: TApiRespose = {
  limit: '10',
  page: 1,
  pagePending:1,
  sortBy: '',
  response: [],
  error: false,
  loading: false,
  totalCount: 0,
  filterDay:[],
  filterPrice: [],
  filterShow: '',
  filterPendingDay:[],
  filterPendingPrice: [],
  filterPendingShow:'',
  currentEstablishment: null,

}

export const establishmentSlice = createSlice({
  name: 'establishmentState',
  initialState,
  reducers: {
    changeLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    fetchEstablishmentFulfilled(state, action: PayloadAction<TResponse>): void {
      state.response = action.payload.models
      state.totalCount = action.payload.totalCount
      state.error = false;
      state.loading=false
    },
    establishmentError(state,{payload}: PayloadAction<boolean>): void {
      state.error = payload
      state.loading=false
    },
    changeLimit(state, action: PayloadAction<string>) {
      state.limit = action.payload
    },
    changePage(state, action: PayloadAction<number>) {
        state.page = action.payload
    },
    changePendingPage(state, action: PayloadAction<number>){
      state.pagePending = action.payload
    },
    deleteItem(state, action: PayloadAction<string>) {
      const indxItem = state.response.findIndex(
        item => item.id === action.payload,
      )
      state.response.splice(indxItem, 1)
    },
    getInstitutionForId(state, action: PayloadAction<TEstablishmentData>) {
      state.currentEstablishment = action.payload
            state.loading = false;

    },
    changeFilter(state, {payload}: PayloadAction<{  day: TWorkingTime[],
  price:TPriceRange[],show: string, name: string}>) {
      if (payload.name === 'current') {
         state.filterDay = payload.day;
      state.filterPrice = payload.price;
      state.filterShow = payload.show;
      }
      if (payload.name === 'pending') {
             state.filterPendingDay = payload.day;
      state.filterPendingPrice = payload.price;
      state.filterPendingShow = payload.show;
      }
    }
  },
})
export const establishmentActions = establishmentSlice.actions

export const establishmentReducer = establishmentSlice.reducer
