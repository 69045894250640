import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TBookingsResponse, TBokingsModel } from "./types";

type TBookingResponse = {
    bookingsList: TBokingsModel[]
    loading: boolean
    error: boolean
    bookingsCount: number
    currentBoking: Partial<TBokingsModel>;
    limit: string,
    page:number
}
const initialState: TBookingResponse = {
    bookingsList: [],
    loading: false,
    error: false,
    bookingsCount: 0,
    currentBoking: {},
    limit: '10',
    page: 1,
}

export const bookingSlice = createSlice({
    name: 'bookingsState', initialState, reducers: {
        getBookingList(state, { payload }: PayloadAction<TBookingsResponse>) {
            state.bookingsList = payload.models
            state.bookingsCount = payload.totalCount
            state.loading = false;
            state.error = false;
        },
        getCurrentBooking(state, { payload }: PayloadAction<TBokingsModel>){
            state.currentBoking = payload;
              state.loading = false;
            state.error = false;
        },
        deleteBooking(state, { payload }: PayloadAction<string>) {
            const indxItem = state.bookingsList.findIndex(
            item => item.id === payload,
            )
            state.bookingsList.splice(indxItem, 1)
            state.loading = false;
            state.error = false;
        },
           changeCategoryPage:(state, { payload }: PayloadAction<number>) => {
            state.page = payload;
        },
            changeLimit: (state, { payload }: PayloadAction<string>) => {
            state.limit = payload;
        },
            changeLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
            bookingError(state, { payload }: PayloadAction<boolean>): void {
    state.error = payload
    state.loading=false
        }, changePage(state, { payload }: PayloadAction<number>) {
                state.page=payload
        }
    }
}
)
export const bookingsActions = bookingSlice.actions
export const bookingsReducer=bookingSlice.reducer