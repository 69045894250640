import { apiPrivate } from "http/http";
import { TUserResponse, TUser } from "./types";

export const getCustomers = async (data: {
   page: number
  limit: string
  name?: string
  sortBy?: string
  order?: string
      startDate?: string
  endDate?:string
}) => {
    const response= await apiPrivate.get<TUserResponse>('/api/v1/admin/users',{params:data})
    return response.data
}
export const createCustomer = async(formData: FormData) => {
  const response = await apiPrivate.post<TUser>('/api/v1/admin/users', formData)
  return response.data
}

export const getCurrentCustomer = async (id: string) => {
  const response = await apiPrivate.get(`/api/v1/admin/users/${id}`)
  return response.data
}
export const editCustomerApi = async ({ id, data }: { id: string, data: FormData }) => {
  await apiPrivate.put(`/api/v1/admin/users/${id}`,data)
}
export const deleteCustomerApi = async (id: string) => {
await apiPrivate.delete(`/api/v1/admin/users/${id}`)

}