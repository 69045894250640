import { PageWrapper, Pagination } from 'components'
import { languageAudit, selectDateLocal } from 'locale'
import {
  MainIfoRolesWrap,
  EstablishmentList,
  SortingList,
} from './EditRole.styled'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks'
import { allEstablishmentActions } from 'store/Establishment'
import { IoIosArrowDown } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import type { Dayjs } from 'dayjs'
import { DatePicker } from 'antd'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import localeEn from 'antd/es/date-picker/locale/en_US'
import { nanoid } from 'nanoid'
import {
  DownloadWrap,
  ElInputWrap,
  InputCurrentWrapper,
  UnderInputCurrentWrapper,
} from 'pages/EstablishmentCurrent'
import { FiSearch } from 'react-icons/fi'
import { RiDownloadFill } from 'react-icons/ri'
import { format } from 'date-fns'
import { AiOutlineEye } from 'react-icons/ai'
import { BottomCustomersWrap } from 'pages/Customers/Customer.styled'
import { SelectLimitCategory } from 'pages/Categories/Categories.styled'
import { allRolesAction } from 'store/Roles'
const { RangePicker } = DatePicker

const firstPartImg =
  'https://dev-api.radius.kitg.com.ua/public/establishment/images/'

export const HistoryRole: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const language = localStorage.getItem('language')
  const { id } = useAppSelector(state => state.rolesState.currentRole)
  const [sort, setSort] = useState<
    'title' | 'category' | 'createdAt' | 'status'
  >('title')
  const { response, totalCount } = useAppSelector(
    state => state.establishmentState,
  )
  const [growth, setGrowth] = useState<boolean>(true)
  const [filter, setFilter] = useState('')
  const [filterDate, setFilterDate] = useState<(Dayjs | null)[] | null>([])
  const currentPage = useAppSelector(state => state.establishmentState.page)
  const { limit, page } = useAppSelector(state => state.establishmentState)
  const totalPage = Math.ceil(totalCount / +limit)
  useEffect(() => {
    if (currentPage > totalPage && totalPage !== 0) {
      dispatch(allEstablishmentActions.changePage(1))
    }
    dispatch(
      allEstablishmentActions.getEstablishmentData({
        limit,
        page,
        sortBy: sort,
        order: growth ? '1' : '-1',
        status: ['pending', 'published', 'unpublished'],
        manager: id,
        title: filter ? filter.toLowerCase() : undefined,
        startDate: filterDate ? filterDate[0]?.toISOString() : undefined,
        endDate: filterDate ? filterDate[1]?.toISOString() : undefined,
      }),
    )
  }, [
    currentPage,
    dispatch,
    filter,
    filterDate,
    growth,
    id,
    limit,
    page,
    sort,
    totalPage,
  ])
  const handleLimitPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(allRolesAction.changeLimit(e.target.value))
  }
  const onPageClick = ({ selected }: { selected: number }) => {
    dispatch(allRolesAction.changePage(selected + 1))
  }
  return (
    <MainIfoRolesWrap>
      <PageWrapper className="roles__history">
        <InputCurrentWrapper>
          <UnderInputCurrentWrapper>
            <ElInputWrap>
              <label>
                <input
                  value={filter}
                  name="search"
                  onChange={e => setFilter(e.target.value)}
                  placeholder={t('establishment.searchEstablishment')!}
                />
                <FiSearch />
              </label>
            </ElInputWrap>
            <ElInputWrap>
              <RangePicker
                locale={language === 'ru' ? localeRu : localeEn}
                placeholder={[t('rangePickerStart'), t('rangePickerEnd')]}
                popupClassName="data__popup"
                className="data__range__picker"
                onChange={setFilterDate}
              />
            </ElInputWrap>
          </UnderInputCurrentWrapper>
          <DownloadWrap>
            <RiDownloadFill /> {t('establishment.export')}
          </DownloadWrap>
        </InputCurrentWrapper>
        <SortingList>
          <li>№</li>
          <li
            onClick={() => {
              setSort('title')
              setGrowth(prev => !prev)
            }}
            id={sort === 'title' ? 'active' : 'inactive'}
            className={growth ? 'up' : 'down'}>
            {t('establishment.establishment')} <IoIosArrowDown />
          </li>
          <li
            onClick={() => {
              setSort('category')
              setGrowth(prev => !prev)
            }}
            id={sort === 'category' ? 'active' : 'inactive'}
            className={growth ? 'up' : 'down'}>
            {t('establishment.category')} <IoIosArrowDown />
          </li>
          <li
            onClick={() => {
              setSort('createdAt')
              setGrowth(prev => !prev)
            }}
            id={sort === 'createdAt' ? 'active' : 'inactive'}
            className={growth ? 'up' : 'down'}>
            {t('establishment.date')}
            <IoIosArrowDown />
          </li>

          <li>{t('establishment.status')}</li>
        </SortingList>
        <EstablishmentList>
          {response.map(item => {
            return (
              <li key={nanoid(4)}>
                <ul>
                  <li>{item.index}</li>
                  <li>
                    <img
                      alt={item.title[0].value}
                      src={firstPartImg + item.images[0].name}
                    />
                    <p>
                      {item.title[languageAudit(language || 'en') || 0].value}
                    </p>
                  </li>
                  <li>
                    {
                      item.category.title[languageAudit(language || 'en') || 0]
                        .name
                    }
                  </li>
                  <li>
                    {format(new Date(item.createdAt), 'MMM d, YYY', {
                      locale: selectDateLocal(language),
                    })}{' '}
                    {t('at')}{' '}
                    {format(new Date(item.createdAt), 'p', {
                      locale: selectDateLocal(language),
                    })}
                  </li>
                  <li>
                    <div id={item.status.toLowerCase()}></div>
                    {t(`status.${item.status}`)}
                  </li>
                  <li onClick={() => navigate(`/edit/${item.id}`)}>
                    <div>
                      {' '}
                      <AiOutlineEye />
                    </div>
                  </li>
                </ul>
              </li>
            )
          })}
        </EstablishmentList>
        {totalPage !== 0 && (
          <BottomCustomersWrap>
            <div>
              {' '}
              {t('showing')}{' '}
              {page === 1
                ? 1
                : page === totalPage
                ? (page - 1) * +limit + 1
                : page * +limit + 1 - +limit}
              -
              {page === 1
                ? totalCount
                : +page === +totalPage
                ? totalCount
                : +limit * +page}{' '}
              {t('of')} {totalCount} {t('items')}
            </div>
            <div>
              {totalPage > 1 && (
                <Pagination
                  onPageChange={onPageClick}
                  pageCount={totalPage}
                  initialPage={page - 1}
                />
              )}
            </div>
            <div>
              {' '}
              {t('show')}
              <SelectLimitCategory value={limit} onChange={handleLimitPage}>
                <option value="2">2</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </SelectLimitCategory>
              {t('items')}
            </div>
          </BottomCustomersWrap>
        )}
      </PageWrapper>
    </MainIfoRolesWrap>
  )
}
