import { apiPrivate } from 'http/http'
import { TGetCategory } from './types'
import { store } from 'store/store'
export const getCategory = async (data: {
  limit: string
  page: number
  name?: string
  sortBy?: string
  order?: string
  startDate?: string
  endDate?:string
}) => {
  const {role}=store.getState().authState
  const response = await apiPrivate.get<TGetCategory>(role==='admin'?'/api/v1/admin/categories':'/api/v1/categories',{params:data})
  return response.data
}

export const createCategory = async (data:FormData) => {
  await apiPrivate.post('/api/v1/admin/categories',data)
}
export const fetchCategoryByName = async (name:string) => {
  const response = await apiPrivate.get<TGetCategory>('/api/v1/admin/categories/',{params:{name}})
  return response.data
}
export const getCurrentCategory = async (id: string) => {
  const response = await apiPrivate.get(`/api/v1/admin/categories/${id}`)
  return response.data
}
export const editCategory = async ({dictates
,id}:{dictates
:FormData,id:string}) => {
  await apiPrivate.put(`/api/v1/admin/categories/${id}`,dictates
)
}

export const deleteCategoryRequest = async (id: string) => {
const response = await apiPrivate.delete(`/api/v1/admin/categories/${id}`)  
  return response.status
}