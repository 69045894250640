import { apiPrivate } from "http/http";
import { TBookingsResponse, TBokingsModel, TCreateBooking } from "./types";

export const getBookingsApi = async (data:{limit:string,page:number,name?:string,sortBy?:string,order?:string, user?:string, startDate?: string
  endDate?:string}) => {
    const response = await apiPrivate.get<TBookingsResponse>('/api/v1/admin/booking',{params:data})
    return response.data
}

export const getCurrentBookingApi = async (id:string) => { 
    const response = await apiPrivate.get<TBokingsModel>(`/api/v1/admin/booking/${id}`)
    return response.data
}
export const createBookingsApi = async (data:TCreateBooking) => {
    await apiPrivate.post('/api/v1/admin/booking',data)
}

export const changeBookingApi = async ({id,data}:{id:string,data:TCreateBooking}) => {
   await apiPrivate.put(`/api/v1/admin/booking/${id}`,data)
}
export const deleteBookingsApi = async (id: string) => {
    await apiPrivate.delete(`/api/v1/admin/booking/${id}`)
}
export const approveBookingApi = async (id: string) => {
    await apiPrivate.patch(`/api/v1/admin/booking/${id}/confirm`)
}

export const cancelBookingApi = async (id: string) => {
    await apiPrivate.patch(`/api/v1/admin/booking/${id}/cancel`)
}