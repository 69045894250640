import { createSlice,PayloadAction } from "@reduxjs/toolkit"
import { TResponseRoles,TModels } from "./types"
type TRoleState = {
    limit: string,
    page: number,
    sortBy?: string,
    error: boolean,
    loading: boolean,
    totalCount: number,
    filterDay: string[],
    filterShow: string[],
    currentRole: Partial<TModels>,
    roles:TModels[]
    
}
const initialState:TRoleState = {
    limit: '10',
    page: 1,
    sortBy: '',
     error: false,
  loading: false,
    totalCount: 0,
   filterDay:[],
    filterShow: [],
    currentRole: {},
  roles:[]
    
}
export const rolesSlice = createSlice({
    name: 'rolesState',
    initialState,
    reducers: {
        changeLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
            state.error = false
        }, changeLimit(state, action: PayloadAction<string>) {
            state.limit = action.payload
        },
        changePage(state, action: PayloadAction<number>) {
            state.page = action.payload
        }, changeError(state, { payload }: PayloadAction<boolean>): void {
            state.error = payload
            state.loading = false
        }, getRoles: (state, { payload }:PayloadAction<TResponseRoles>) => {
            state.roles = payload.models;
            state.totalCount = payload.totalCount;
            state.error = false;
            state.loading = false;
        }, getCurrentRole:(state, { payload }: PayloadAction<TModels>) =>{
            state.currentRole = payload;
            state.error = false;
            state.loading = false;
        },
        deleteRole(state, action: PayloadAction<string>) {
      const indxItem = state.roles.findIndex(
        item => item.id === action.payload,
      )
      state.roles.splice(indxItem, 1)
    },
        
    }
})
 export const rolesActions = rolesSlice.actions

export const rolesReducer = rolesSlice.reducer