import {
  DeleteWrap,
  DeleteBtnWrap,
  DeleteTitle,
  DeleteAfterTitle,
  TitleWrap,
} from '../FormDelete/FormDelete.styled'
import { allCustomersAction } from 'store/Customers'
import { useAppDispatch } from 'hooks'
import { RxCross2 } from 'react-icons/rx'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
type TProps = {
  id: string | undefined
  close: () => void
}
export const FormDeleteCustomer: React.FC<TProps> = ({ id, close }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const onDeleteClick = () => {
    id && dispatch(allCustomersAction.deleteCustomerAction(id))
    navigate('/customers')
  }
  return (
    <DeleteWrap>
      <TitleWrap>
        <DeleteTitle>{t('deleteCustomerTitle')}</DeleteTitle>
        <button onClick={close}>
          <RxCross2 />
        </button>
      </TitleWrap>
      <DeleteAfterTitle>{t('deleteCustomerSure')} </DeleteAfterTitle>
      <DeleteBtnWrap>
        <button onClick={onDeleteClick}>
          <RiDeleteBinLine />
          <p>{t('establishment.delete')}</p>
        </button>
        <button onClick={close}>{t('rolesLast.cancel')}</button>
      </DeleteBtnWrap>
    </DeleteWrap>
  )
}
