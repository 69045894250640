import { createPortal } from 'react-dom'
import { ReactNode, useEffect } from 'react'
import { Overlay, ModalWindow } from './Modal.styled'
import { useSpring } from '@react-spring/web'
const modalRoot = document.querySelector('#modal') as HTMLDivElement
type TModal = {
  children: ReactNode
  closeModal: () => void
}
export const Modal: React.FC<TModal> = ({ children, closeModal }) => {
  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { key } = event
      if (key === 'Escape') {
        closeModal()
      }
    }
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [closeModal])

  const spring = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 80 },
  })
  const springOverlay = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 100 },
  })
  const onClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }
  return createPortal(
    <Overlay onClick={onClick} style={springOverlay}>
      <ModalWindow style={spring}>{children}</ModalWindow>
    </Overlay>,
    modalRoot,
  )
}
