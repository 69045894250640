import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en-US'
 export const selectDateLocal = (language: string | null) => {
    if (language === 'ru') {
      return ruLocale
    }
    if (language === 'en') {
      return enLocale
    }
    if (language !== 'en' && language !== 'ru') {
      return enLocale
    }
    if (!language) {
      return enLocale
    }
 }
   export const languageAudit = (currentLanguage: string) => {
    if (currentLanguage === 'ru') {
      return 1
    }
    if (currentLanguage === 'en') {
      return 0
    }
    if (currentLanguage !== 'en' && currentLanguage !== 'ru') {
      return 0
    }
       if (!currentLanguage ) {
      return 0
    }
  }