import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const CustomerCharItem = styled.li`
  border-bottom: 1px solid #ededed;
  padding: 12px 20px;
  height: 68px;
`
export const LinkUserEdit = styled(Link)`
  width: 44px;
  height: 44px;
  background: #2bb54b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    color: #ffffff;
  }
`
export const CharList = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  li {
    display: flex;
    align-items: center;
    &:nth-child(1) {
      width: 87px;
      padding: 0 10px 0 0;
    }

    &:nth-child(2) {
      width: 228px;
      img {
        object-fit: cover;
        width: 44px;
        height: 44px;
        margin-right: 8px;
        border-radius: 50%;
      }
      p {
        display: flex;
        height: 100%;
        align-items: center;
        flex-wrap: wrap;
        overflow: auto;
      }
    }
    &:nth-child(3) {
      width: 182px;
    }
    &:nth-child(4) {
      padding-left: 3px;
      width: 235px;
    }
    &:nth-child(5) {
      padding-left: 3px;
      width: 183px;
    }
    &:nth-child(6) {
      padding-left: 3px;
      width: 150px;
      div {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
        &#active {
          background-color: #19dda2;
        }
        &#blocked {
          background-color: #dd1919;
        }
      }
    }
  }
`
