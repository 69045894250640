import { apiPrivate } from "http/http"
import { TResponseRoles,TNewRole,TModels } from "./types"
type TParams = {
    limit: string
    page: number
        search?: string,
     sortBy?: string
    order?: string
     startDate?: string
  endDate?: string}


export const getRolesApi =async (data:TParams) => {
    const response = await apiPrivate.get<TResponseRoles>('/api/v1/admin/managers',{params:data})
    return response.data
}
export const createRolesApi = async (data:TNewRole) => {
    await apiPrivate.post('/api/v1/admin/managers',data)
}
export const editRoleApi = async ({ data, id }: { data: TNewRole, id: string }) => {
    await apiPrivate.put(`/api/v1/admin/managers/${id}`,data)
}

export const getCurrentRoleApi = async (id: string) => {
    const response = await apiPrivate.get<TModels>(`/api/v1/admin/managers/${id}`)
    return response.data
}
export const deleteRoleApi = async (id: string) => {
    await apiPrivate.delete(`/api/v1/admin/managers/${id}`)
}