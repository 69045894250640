import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import { allCustomersAction } from './actions';
import { TUserResponse, TUser } from './types';
import { store } from 'store/store';
import { getCustomers,createCustomer,getCurrentCustomer,editCustomerApi,deleteCustomerApi } from './api';
import { t } from 'i18next';
const { getCustomerAction,editCustomerAction,deleteUser,deleteCustomerAction,changeError,changeLoading,getCurrentUser,getUserAction,getCustomerList,createCustomerAction } = allCustomersAction;

function* getCustomerWorker({ payload }: ReturnType<typeof getCustomerAction>) {
    yield put(changeLoading(true))
    try {
        const response: TUserResponse = yield call(getCustomers, payload)
        yield put(getCustomerList(response))
    } catch (error) {
        yield put(changeError(true))
    }
} function* creacteCustomerWorker({ payload }: ReturnType<typeof createCustomerAction>) {
    yield put(changeLoading(true))
    const{limit,page}=store.getState().customerState
    try {
      const response:TUser =  yield call(createCustomer, payload)
        if (response)
            yield put(getCustomerAction({ limit, page }))
        yield put(changeLoading(false))
         toast.success(t('errorsInput.createdSuccess'))
    } catch (error) {
                yield put(changeError(true))
             toast.error(t('errorsInput.errorPhone'))
    }
}

function* getUserWorker({payload}: ReturnType<typeof getUserAction>) {
    yield put(changeLoading(true))
    try {
        const response: TUser = yield call(getCurrentCustomer, payload);
        yield put(getCurrentUser(response))

    } catch (error) {
         yield put(changeError(true))
    }
}
function* editCustomerWorker({ payload }: ReturnType<typeof editCustomerAction>) {
        const{limit,page}=store.getState().customerState

    yield put(changeLoading(true))
    try {
        yield call(editCustomerApi, payload)
        yield put(getCustomerAction({ limit, page }))
         toast.success(t('errorsInput.changedSuccess'))
        yield put(changeLoading(false))
       
    } catch (error) {
        yield put(changeError(true))
        toast.error(t('errorsInput.toastError'))
    }
}
function* deleteCustomerWorker({ payload }: ReturnType<typeof deleteCustomerAction>) {
    yield put(changeLoading(true))
    try {
        yield call(deleteCustomerApi, payload)
        yield put (deleteUser(payload))
       toast.success(t('errorsInput.deleteSuccess'))
         yield put(changeLoading(false))
    } catch (error) {
        yield put(changeError(true))
         toast.error(t('errorsInput.toastError'))
    }
}


export function* customerWatcher() {
    yield takeLatest(getCustomerAction, getCustomerWorker);
    yield takeLatest(createCustomerAction, creacteCustomerWorker);
    yield takeLatest(editCustomerAction, editCustomerWorker);
    yield takeLatest(deleteCustomerAction, deleteCustomerWorker)
    
yield takeLatest(getUserAction,getUserWorker)}