import { createAction } from "@reduxjs/toolkit";
import { bookingsActions } from "./reducers";
import { TCreateBooking } from "./types";

type TChange = {
    data:TCreateBooking,id:string
}

export const allBookingActions = {
    ...bookingsActions,
    getBookingAction: createAction<
        {limit:string,page:number,name?:string,sortBy?:string,order?:string,user?:string, startDate?: string
  endDate?:string}>('bookingsState/getBookingAction'),
    getCurrentBookingAction: createAction<string>('bookingsState/getCurrentBookingAction'),
    deleteBookingAction: createAction<string>('bookingsState/deleteBookingAction'),
    changeBookingAction: createAction<TChange>('bookingsState/changeBookingAction'),
    createBookingAction:createAction<TCreateBooking>('bookingsState/createBookingAction'),
    approveBookingAction: createAction<string>('bookingsState/approveBookingAction'),
    cancelBookingAction:createAction<string>('bookingsState/cencellBookingAction')
}